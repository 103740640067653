import { PdContainer, TopImg, Container } from "./components";
import { TopBar } from "../../components/topBar";
import { Packets } from "./packets";
import { ContactForm } from "../diventa_pilota/contactForm";
import styled from "styled-components";
const DontStopHere = styled.div`
  h2 {
    margin-top: 100px;
    margin-bottom: 0;
    font-size: 40px;
  }
  h1 {
    margin-top: 0;
  }
`;
export function JumpOnSimulator() {
  return (
    <>
      <TopBar fixed />
      <Container>
        <TopImg $bgImage="/prova-simulatore/top.webp" />

        <PdContainer>
          <h1>COS’è IL SIMRACING</h1>
          <h2>
            La rivoluzione del motorsport, IL primo step per l’automobilismo
            sportivo
          </h2>
          <p>
            Che tu sia un principiante desideroso di esplorare il mondo delle
            corse o un pilota esperto in cerca di una piattaforma per affinare
            le tue abilità, il Sim Racing rappresenta la disciplina migliore per
            accumulare esperienza di gara a basso costo.
          </p>

          <h1>Inizia subito</h1>
          <Packets />

          <h2>
            Acquista il tuo pacchetto e contattaci <br /> per prenotare la tua
            sessione
          </h2>

          <DontStopHere>
            <h2>Non fermarti qui</h2>

            <h1>
              ALLENATI CON NOI E <br />
              PARTECIPA AI CAMPIONATI
            </h1>
          </DontStopHere>
          <img src="/loghi/aci.webp"></img>
        </PdContainer>
        <TopImg $bgImage="/prova-simulatore/bottom.webp" />
        <PdContainer>
          <h2>COME PARTECIPARE</h2>
          <p>
            Fissa un appuntamento presso il nostro Racelab, in cui proverai i
            nostri simulatori affiancato dal nostro head coach che ti aiuterà a
            scegliere quale competizione è più adatta al tuo stile di guida!
            <br />
            <br />
            Farai parte di un team eSport che vuole gettare le basi per i piloti
            del futuro, ti forniamo il nostro pieno supporto, sia per analisi
            dati che per coaching 1 to 1 per aiutarti a raggiungere il miglior
            livello possibile.
          </p>
          <h1>Scrivici per avere più informazioni</h1>
          <ContactForm />
        </PdContainer>
      </Container>
    </>
  );
}
