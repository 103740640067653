import { useEffect, useState } from "react";
import { BecomePilot, CustomRadio } from "./components";
import styled from "styled-components";
import { SubmitButton, TitledInput } from "./components";
import SignatureCanvas from "react-signature-canvas";
import { useWindowSize } from "@uidotdev/usehooks";
import React from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { ViewPdf } from "./pdfAsd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCheckoutAsdRegisterMutation } from "../../generated/graphql";
import { AgreementDoc } from "./agreement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

const RegexCodeFisc =
  "^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$";
const SmallInput = styled(TitledInput)`
  font-size: 17px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  & > input {
    margin-top: 0px;
    font-size: 12px;
  }
`;
const SaveButton = styled(SubmitButton)`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 21474836450 !important;
  @media screen and (orientation: portrait) {
    & {
      visibility: hidden;
    }
  }
`;

const AsdContainer = styled(BecomePilot)`
  height: 100%;
  margin-bottom: 50px;
`;
const RotateAlert = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  color: black;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  z-index: 21474836449 !important;
  @media screen and (orientation: landscape) {
    & {
      visibility: hidden;
    }
  }
`;

const AgrementConainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  color: black;
  padding: 0;
  overflow: scroll;
`;

function checkISUnderage(birthday: string) {
  const dateObj = new Date(birthday);
  const today = new Date();
  var age = today.getFullYear() - dateObj.getFullYear();
  var m = today.getMonth() - dateObj.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < dateObj.getDate())) {
    age--;
  }
  return age < 18;
}

export type FormData = {
  NAME: string;
  SURNAME: string;
  CODE: string;
  EMAIL: string;
  BIRTHDAY: string;
  BIRTHPLACE: string;
  RESIDENCE_CITY: string;
  RESIDENCE_ADDRESS: string;
  CEL: string;
  IS_UNDERAGE: boolean;
  PARENT_NAME: string;
  PARENT_SURNAME: string;
  PARENT_CODE: string;
  PARENT_EMAIL: string;
  PARENT_BIRTHDAY: string;
  PARENT_BIRTHPLACE: string;
  PARENT_RESIDENCE_CITY: string;
  PARENT_RESIDENCE_ADDRESS: string;
  PARENT_CEL: string;
  HOW_MET_US: string;
};

export function AsdRegister() {
  const [fields, setFields] = useState<FormData | undefined>(undefined);
  const steps = {
    fields: <FieldsStep onSubmit={(data) => setFields(data)} />,
    signature: <SignatureStep fields={fields} />,
  };

  const [currentStep, setCurrentStep] = useState<keyof typeof steps>("fields");
  useEffect(() => {
    fields && setCurrentStep("signature");
  }, [fields]);

  if (!isMobile) {
    return (
      <div style={{ margin: 10 }}>
        <h1>Questa sezione è disponibile solo su dispositivi mobili</h1>
      </div>
    );
  }
  return <AsdContainer>{steps[currentStep]}</AsdContainer>;
}

function Agreement(props: { onClose: () => void }) {
  return (
    <AgrementConainer>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          padding: 10,
        }}
      >
        <FontAwesomeIcon
          onClick={props.onClose}
          icon={faWindowClose}
          size="2x"
        ></FontAwesomeIcon>
      </div>
      <AgreementDoc></AgreementDoc>
    </AgrementConainer>
  );
}

function SignatureStep(props: { fields: FormData }) {
  const [showSignature, setShowSignature] = useState(false);
  const canvRef = React.createRef<ReactSignatureCanvas>();
  const [signature, setSignature] = useState<false | string>(false);
  const [pdfString, setPdfString] = useState<false | string>(false);
  const [createCheckout] = useCheckoutAsdRegisterMutation();
  function GoCheckout() {
    createCheckout({
      variables: {
        details: JSON.stringify(props.fields),
        pdfString: pdfString || "",
      },
    }).then((res) => {
      window.location.href = res.data?.checkoutCreate.checkout.webUrl;
    });
  }

  const size = useWindowSize();
  function getSignature() {
    setSignature(canvRef.current.getTrimmedCanvas().toDataURL("image/png"));
    setShowSignature(false);
  }
  return (
    <>
      <h1>Procedi con la firma</h1>
      <ViewPdf
        form={props.fields}
        signature={signature}
        onChange={setPdfString}
      />

      <SubmitButton type="button" onClick={() => setShowSignature(true)}>
        Firma
      </SubmitButton>

      {signature && pdfString && (
        <>
          <SubmitButton
            type="button"
            onClick={GoCheckout}
            style={{ marginTop: "30px" }}
          >
            Prosegui
          </SubmitButton>
        </>
      )}
      {showSignature && (
        <>
          <SignatureCanvas
            ref={canvRef}
            penColor="black"
            canvasProps={{
              className: "sigCanvas",
              width: size.width,
              height: size.height,
            }}
          />
          <RotateAlert>Ruota lo schermo</RotateAlert>

          <SaveButton onClick={getSignature}>Salva</SaveButton>
        </>
      )}
    </>
  );
}

function FieldsStep(props: { onSubmit: (data: FormData) => void }) {
  const { register, handleSubmit, watch } = useForm<FormData>();
  const [showAgreement, setShowAgreement] = useState(false);
  const AGE = watch("BIRTHDAY");
  const Radio = (props: { name: string }) => (
    <div>
      <CustomRadio
        {...register("HOW_MET_US")}
        type="radio"
        value={props.name}
        id={props.name.replace(/\s+/g, "-")}
      />
      <label htmlFor={props.name.replace(/\s+/g, "-")}>{props.name}</label>
    </div>
  );
  const onSubmit: SubmitHandler<FormData> = (data) => {
    props.onSubmit(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Diventa un pilota</h1>
        <SmallInput>
          Nome
          <input {...register("NAME")} required />
        </SmallInput>
        <SmallInput>
          Cognome
          <input {...register("SURNAME")} required />
        </SmallInput>
        <SmallInput>
          Data di Nascita
          <input type="date" {...register("BIRTHDAY")} required />
        </SmallInput>
        <SmallInput>
          Luogo di nascita
          <input {...register("BIRTHPLACE")} required />
        </SmallInput>
        <SmallInput>
          Codice Fiscale
          <input
            style={{ textTransform: "uppercase" }}
            {...register("CODE")}
            pattern={RegexCodeFisc} /* required */
          />
        </SmallInput>
        <SmallInput>
          Città di residenza
          <input {...register("RESIDENCE_CITY")} required />
        </SmallInput>
        <SmallInput>
          Indirizzo di residenza
          <input {...register("RESIDENCE_ADDRESS")} required />
        </SmallInput>
        <SmallInput>
          Email
          <input {...register("EMAIL")} required type="email" />
        </SmallInput>
        <SmallInput>
          Telefono
          <input {...register("CEL")} required type="tel" />
        </SmallInput>
        {checkISUnderage(AGE) && (
          <>
            <h2 style={{ marginTop: 30 }}>
              Atleta minorenne, inserire i dati del genitore
            </h2>
            <SmallInput>
              Nome
              <input {...register("PARENT_NAME")} required />
            </SmallInput>
            <SmallInput>
              Cognome
              <input {...register("PARENT_SURNAME")} required />
            </SmallInput>
            <SmallInput>
              Data di Nascita
              <input type="date" {...register("PARENT_BIRTHDAY")} required />
            </SmallInput>
            <SmallInput>
              Luogo di nascita
              <input {...register("PARENT_BIRTHPLACE")} required />
            </SmallInput>
            <SmallInput>
              Codice Fiscale
              <input
                {...register("PARENT_CODE")}
                pattern={RegexCodeFisc}
                style={{ textTransform: "uppercase" }}
                /* required */
              />
            </SmallInput>
            <SmallInput>
              Città di residenza
              <input {...register("PARENT_RESIDENCE_CITY")} required />
            </SmallInput>
            <SmallInput>
              Indirizzo di residenza
              <input {...register("PARENT_RESIDENCE_ADDRESS")} required />
            </SmallInput>
            <SmallInput>
              Email
              <input {...register("PARENT_EMAIL")} required type="email" />
            </SmallInput>
            <SmallInput>
              Telefono
              <input {...register("PARENT_CEL")} required type="tel" />
            </SmallInput>
          </>
        )}
        <h2>Come ci hai conosciuti?</h2>
        <div>
          <Radio name="Driving Italia"></Radio>
          <Radio name="Atletica"></Radio>
          <Radio name="MindUp"></Radio>
          <Radio name="DSC"></Radio>
          <Radio name="Amici"></Radio>
          <Radio name="Social"></Radio>
          <Radio name="Online"></Radio>
          <Radio name="Altro"></Radio>
        </div>
        {showAgreement && (
          <Agreement onClose={() => setShowAgreement(false)}></Agreement>
        )}
        <div style={{ margin: "10px 0px" }}>
          Proseguendo accetti{" "}
          <span
            style={{ textDecoration: "underline" }}
            onClick={() => setShowAgreement(true)}
          >
            lo statuto dell' associazione, il regolamento e il trattamento dei
            dati personali
          </span>
        </div>
        <SubmitButton type="submit">Prosegui</SubmitButton>
      </form>
    </>
  );
}
