import { useCheckoutCreateMutation } from "../../generated/graphql";

export function useCheckout() {
  const [createCheckout] = useCheckoutCreateMutation();

  function GoCheckout(id: string) {
    createCheckout({
      variables: { id: "gid://shopify/ProductVariant/" + id },
    }).then((res) => {
      window.location.href = res.data?.checkoutCreate.checkout.webUrl;
    });
  }
  return GoCheckout;
}
