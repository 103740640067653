import styled from "styled-components";
import { TopBar } from "../../components/topBar";
import { SimBox } from "./SimBox";
import { GranPrixRow } from "./grandPrixRow";

import { PdContainer, TopImg, Container } from "./components";

export function OurServices() {
  return (
    <>
      <TopBar fixed />

      <Container>
        <TopImg $bgImage="/our-services/top.webp" />
        <PdContainer>
          <h1>BENVENUTI AL RACELAB</h1>
          <h2>VIENI A PROVARE I NOSTRI SIMULATORI DINAMICI</h2>
          <p>
            Scegli tra i nostri simulatori quello che preferisci, prenota la tua
            sessione e vieni al raceLAB.
          </p>

          <h1>Prenota la tua sessione di prova</h1>

          <SimBox />
          <p>*La sessione di prova ha una durata totale di 10 minuti*</p>
          <h2>
            Acquista il tuo pacchetto e contattaci per prenotare la tua sessione
          </h2>
          <br />
          <br />
          <br />
          <br />
          <h1>
            Se vuoi venire con i tuoi amici, <br /> prova i Gran Premi!
          </h1>
          <GranPrixRow />
          <h2>
            Acquista il tuo pacchetto e contattaci <br /> per prenotare il tuo
            GP
          </h2>
        </PdContainer>
      </Container>
    </>
  );
}
