import styled from "styled-components";

export const BgContainer = styled.div<{
  $bgImage?: string;
  gradient?: boolean;
}>`
  scroll-margin-top: var(--scroll-offset);

  background-image: ${(props) =>
    props.gradient
      ? `linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(${props.$bgImage})`
      : `url(${props.$bgImage})`};

  height: calc(100vh - 100px);
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.first {
    height: 100vh;
  }
  &.full {
    height: 100%;
  }
`;
