import { animated, useInView } from "@react-spring/web";
import styled from "styled-components";

const TeamPicutresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const TeamPicture = styled.div<{ $bgImage?: string }>`
  position: relative;
  display: flex;
  height: 90vh;
  justify-content: center;
  color: white;
  background-image: url(${(props) => props.$bgImage});

  transition: 2s;
  &::before {
    inset: 0;
    content: "";
    background-color: black;
    z-index: 1;
    height: 100%;
    position: "absolute";
    width: 100%;
    opacity: 0.5;
  }

  & > div:nth-child(1) {
    font-family: "Forma DJR Micro";
    font-weight: normal;
    text-align: center;
    opacity: 1;
    font-size: 18px;
    margin-top: 30px;
    z-index: 1;
    position: absolute;
    padding: 50px;
    bottom: 0;
  }

  & > div:nth-child(1)::first-line {
    font-family: "Handel Gothic D";
    text-align: center;
    font-weight: bold;
    font-style: normal;
    font-size: 25px;
    vertical-align: middle;
  }
  background-position: center;
  background-size: cover;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
`;
const Title = styled.h1`
  font-family: "Handel Gothic D";
  font-weight: bold;
  position: relative;
  font-style: normal;
  font-size: 50px;
  color: var(--violet-color);
  text-align: center;
  margin-top: 150px;
`;
function AnimatedText(props: { children: JSX.Element }) {
  const [ref, springs] = useInView(() => ({
    config: { duration: 600, delay: 200 },

    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  }));
  return (
    <animated.div ref={ref} style={springs}>
      {props.children}
    </animated.div>
  );
}
export function Ambassadors() {
  return (
    <>
      <Title>AMBASSADORS</Title>
      <TeamPicutresContainer id="train">
        <TeamPicture $bgImage="/trainers/alberto.webp">
          <AnimatedText>
            <>
              Alberto Di Folco <br />
              Pilota Lamborghini GT3
            </>
          </AnimatedText>
        </TeamPicture>
        <TeamPicture $bgImage="/trainers/amos.webp">
          <AnimatedText>
            <>
              Amos Laurito <br />
              Sim Driver/Streamer/Content Creator Tatum ESport Founder
            </>
          </AnimatedText>
        </TeamPicture>
        <TeamPicture $bgImage="/trainers/filippo.webp">
          <AnimatedText>
            <>
              Filippo Barberi <br />
              Pilota TCR Italy
            </>
          </AnimatedText>
        </TeamPicture>
      </TeamPicutresContainer>
    </>
  );
}
