import styled from "styled-components";
import { DoubleRow } from "./components";
import { useCheckout } from "./useCheckout";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;

  flex: 1;

  height: 100%;
  p {
    height: 50px;
    font-size: 12px;
  }
  h2 > u {
    color: var(--violet-color);
  }
`;

export function GranPrixRow() {
  const GoCheckout = useCheckout();

  return (
    <DoubleRow>
      <Container>
        <h2>
          <u>MINI Gran Premio</u>
        </h2>
        <h2>
          PROVE LIBERE - 5 min <br />
          QUALIFICHE - 10 min
          <br /> Gara 7 giri
        </h2>
        <p>
          La durata dell’ esperienza è di 45 min ogni 4 persone, si potrà
          accedere su prenotazione da un Minimo di 3 persone ad un Max 16
          persone (con finali A-B-C-D)
        </p>
        <button onClick={() => GoCheckout("49145208734007")}>
          <u>25</u>€ a persona
        </button>
      </Container>
      <Container>
        <h2>
          <u>FULL Gran Premio</u>
        </h2>
        <h2>
          PROVE LIBERE - 10 min <br />
          QUALIFICHE - 10 min
          <br /> Gara 10 giri
        </h2>
        <p>
          La durata dell’ esperienza è di 1 ora ogni 2 persone, si potrà
          accedere su prenotazione da un Minimo di 3 persone ad un Max 16
          persone (con finali A-B-C-D)
        </p>
        <button onClick={() => GoCheckout("49145208766775")}>
          <u>35</u>€ a persona
        </button>
      </Container>
    </DoubleRow>
  );
}
