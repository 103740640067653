import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useCheckoutCreateMutation } from "../generated/graphql";
export const RaceButton = styled.button`
  background-color: rgb(83, 123, 137);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  font-family: "Handel Gothic D";
  font-weight: bold;
  font-style: normal;
  border-radius: 12px;
  max-width: 300px;

  &.numeric {
    font-variant-numeric: tabular-nums;
    font-family: system-ui;
  }

  &:hover {
    background-color: rgb(83, 137, 132);
    color: white;
  }
  &.active {
    background-color: #82368c;
  }
  &:disabled {
    background-color: #1c0320;
    opacity: 0.5;
    cursor: auto;
  }
`;

export function BuyButton(props: {
  children: ReactElement | string;
  id: string;
  disabled?: boolean;
  className?: string;
}) {
  const [createCheckout] = useCheckoutCreateMutation();
  function GoCheckout() {
    createCheckout({ variables: { id: props.id } }).then((res) => {
      window.location.href = res.data?.checkoutCreate.checkout.webUrl;
    });
  }

  return (
    <RaceButton
      disabled={props.disabled}
      onClick={GoCheckout}
      className={props.className}
    >
      {props.children}
    </RaceButton>
  );
}
