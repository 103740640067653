import styled from "styled-components";
import { slideTop } from "../components/animations";
import { animated, useInView } from "@react-spring/web";

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 300px));
  column-gap: 100px;
  padding: 50px;
  margin-top: 50px;
  grid-auto-rows: auto; /* Rows will size to content */
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    grid-row-gap: 50px;
  }
  justify-content: space-around;
  grid-row-gap: 20vh;
`;
const InfoBlockContainer = styled.div`
  display: flex;
  font-size: 20px;
  & > div:nth-child(1) {
    color: var(--violet-color);
    font-family: "Handel Gothic D";
    font-weight: bold;
    font-style: normal;
    font-size: 70px;
  }
  & > div:nth-child(2) {
    color: white;
    text-align: justify;
    overflow-wrap: break-word;

    hyphens: auto;
    hyphenate-character: "-";
    overflow-wrap: break-word;
  }
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
`;
const DrivingSucces = styled.div`
  font-family: "Handel Gothic D";
  font-weight: bold;
  font-style: normal;
  font-size: 8vw;
  color: rgb(10, 17, 19);
  text-align: center;
  grid-column: 1 / -1;
  animation: ${slideTop} 1s ease forwards; /* Apply the animation */
`;
function InfoBlock(props: { title: string; text: string }) {
  const [ref, springs] = useInView(() => ({
    from: {
      x: 100,
    },
    to: {
      x: 0,
    },
  }));
  return (
    <InfoBlockContainer as={animated.div} ref={ref} style={springs}>
      <div>{props.title}</div>
      <div>{props.text}</div>
    </InfoBlockContainer>
  );
}

export function DrivingSucess() {
  const [ref, springs] = useInView(() => ({
    from: {
      x: -100,
    },
    to: {
      x: 0,
    },
  }));
  return (
    <ContainerGrid>
      <InfoBlock
        title="VISION"
        text="raceX è un progetto ideato da piloti di auto da corsa per piloti di auto da corsa,  che integra sport, psicologia del potenziamento, sim-racing e motorsport per formare i campioni del futuro. Un concept innovativo caratterizzato da costi accessibili a tutti ed ottimizzazione dei tempi necessari per lo sviluppo.
"
      />
      <InfoBlock
        title="MISSION"
        text="You bring the will, we’ll find the way!”
        Sappiamo quanto è ampio e complesso il mondo del motorsport, per questo lavoreremo con qualsiasi appassionato, professionista o aspirante tale per fissare un obiettivo raggiungibile nel motorsport ed individueremo la strada per raggiungerlo, insieme agli strumenti necessari.
        "
      />
      <InfoBlock
        title="VALUES"
        text="Crediamo nella passione, nella dedizione, nella disciplina e nella formazione a 360° del pilota.
Questi quattro pilastri sono la linfa vitale che alimenta ogni professionista, rappresentano le fondamenta su cui costruire il proprio percorso verso l'eccellenza.
Il valore che sta alla base di questo progetto è l’attenzione che si ha verso l’atleta, accompagnandolo in ogni tappa della sua crescita sportiva, ma sopratutto personale.
"
      />
    </ContainerGrid>
  );
}
