import styled from "styled-components";
import { useCheckout } from "./useCheckout";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    max-width: 600px;
  }
  p {
    margin-bottom: 50px;
    margin-top: 20px;
  }
`;

export function Packets() {
  const GoCheckout = useCheckout();

  return (
    <Container>
      <h2>Pacchetti orari</h2>
      <button onClick={() => GoCheckout("49145107743031")}>
        1 ora <u>49</u>€
      </button>
      <button onClick={() => GoCheckout("49145107808567")}>
        5 h <u>99</u>€
      </button>
      <p>
        * Acquistando il pacchetto orario potrai sfruttarlo decidendo tu quando
        fare le tue sessioni da 1 ora*
      </p>
      <h2>Abbonamenti ore illimitate</h2>
      <button onClick={() => GoCheckout("49145107874103")}>
        1 mese <u>249</u>€
      </button>
      <button onClick={() => GoCheckout("49145107906871")}>
        3 mesi <u>499</u>€
      </button>
      <p>
        **Facendo l’abbonamento avrai accesso illimitato anche alla nostra area
        training**
      </p>
    </Container>
  );
}
