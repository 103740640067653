import styled from "styled-components";

const Video = styled.video`
  margin-top: 125px;
  width: 100%;
  pointer-events: none;
  height: auto;
  display: block;
`;
const VideoContainer = styled.div`
  position: relative;

  margin: 0 auto;
`;

export function VideoBanner() {
  return (
    <VideoContainer>
      <iframe
        width="100%"
        style={{ aspectRatio: "16 / 9", border: 0 }}
        src="https://www.youtube.com/embed/tsupwH4NU_E?si=V7HH2hC33qUGN6M5&amp;controls=0"
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </VideoContainer>
  );
}
