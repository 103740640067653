import styled from "styled-components";
import { RaceButton } from "../components/button";
import { BgContainer } from "../components/bgContainer";

const Container = styled.div`
  display: flex;
  z-index: 4;
`;

export const FindServices = styled(RaceButton)`
  margin: 25px 50px;
  z-index: 4;
  background-color: var(--violet-color);
`;
const ButtonContainer = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export function WelcomePage() {
  return (
    <BgContainer $bgImage="/home/top.webp" className="first" id="welcome">
      <Container>
        <ButtonContainer>
          <a href="/ourservices">
            <FindServices>PROVA IL SIMULATORE</FindServices>
          </a>
        </ButtonContainer>
      </Container>
    </BgContainer>
  );
}
