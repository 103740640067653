import { TextSection } from "../components/TextSection";
import { BgContainer } from "../components/bgContainer";
import styled from "styled-components";
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 50px;
  align-content: center;
  align-items: center;
  justify-items: stretch;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 100px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding-left: 10px;
    padding-right: 10px;
  }
  & > div:nth-child(2) {
    justify-self: center;
    width: 100%;
    height: 450px;
  }
`;
const Phone = styled.a`
  color: white;
`;
export function ContactUs() {
  return (
    <BgContainer className="full" id="contact" gradient>
      <Container>
        <TextSection
          text={
            <>
              <Phone href="tel:+393298864486"> + 39 329 886 4486</Phone>
              <br />
              info@race-x.it
            </>
          }
          subtitle={"contattaci"}
          title="Racelab A.S.D."
        />
        <div>
          <iframe
            title="map"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            className="_iub_cs_activate"
            src="about:blank"
            {...{
              suppressedsrc:
                "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJo8e4OZaUhkcRWqY0C9Slrj0&key=AIzaSyDW0J2cWhz7IHSachcUqixzFfbdeKu2wfM",
            }}
          ></iframe>
        </div>
      </Container>
    </BgContainer>
  );
}
