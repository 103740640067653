import { TopBar } from "./components/topBar";
import { WelcomePage } from "./sections/welcome";
import "./app.css";

import { ChiSiamo } from "./sections/chiSiamo";
import { TeamPictures } from "./sections/teamPictures";

import { DiventaPilota } from "./sections/diventa_pilota/diventaPilota";
import { ContactUs } from "./sections/contactUs";
import { Footer } from "./sections/footer";
import { Shop } from "./sections/shop/shop";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AsdRegister } from "./sections/diventa_pilota/asdRegister";
import { VideoBanner } from "./sections/videoBanner";
import { TimeAttack } from "./sections/timeAattack";
import { OurServices } from "./sections/services/our_services";
import { JumpOnSimulator } from "./sections/services/jumOnSimulator";
import { ChampionsFuture, Services } from "./sections/services";
import { DrivingSucess } from "./sections/drivingSucess";
import { Ambassadors } from "./sections/diventa_pilota/ambassadors";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainWebsite />,
  },
  {
    path: "/registerasd",
    element: <AsdRegister />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/timeAttack",
    element: <TimeAttack />,
  },
  {
    path: "/ourservices",
    element: <OurServices />,
  },
  {
    path: "/trysim",
    element: <JumpOnSimulator />,
  },
]);
function App() {
  return <RouterProvider router={router} />;
}
function MainWebsite() {
  return (
    <div className="App">
      <TopBar />
      <WelcomePage />
      <VideoBanner />
      <DrivingSucess />

      <ChampionsFuture />
      <ChiSiamo />

      <TeamPictures />
      <Ambassadors />

      <Services />
      {/*    <Pricing /> */}
      <Shop />
      <DiventaPilota />
      <ContactUs />

      <Footer />
    </div>
  );
}
export default App;
