import styled from "styled-components";
import { TitledInput } from "./components";
import { useForm } from "@formspree/react";
const Container = styled.div`
  input {
    max-width: 600px;
    border-color: var(--violet-color);
  }
  input::placeholder {
    color: white;
    font-family: "Handel Gothic D";
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  button {
    width: 100px;
    margin-top: 20px;
    margin-left: auto;
  }
  textarea {
    background-color: transparent;
    font-family: "Handel Gothic D";
    color: white;
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    border: 3px solid var(--violet-color);
    border-radius: 5px;
    height: 200px;
    box-sizing: border-box;
  }
`;
export function ContactForm() {
  const [state, handleSubmit, reset] = useForm("xkndlepn");
  if (state.succeeded) {
    return <div>Grazie per averci contattato!</div>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <TitledInput>
          <input
            placeholder="Email"
            required
            id="email"
            type="email"
            name="email"
          />
        </TitledInput>

        <textarea
          placeholder="Il tuo messaggio"
          required
          id="message"
          name="message"
          value="Ciao! Vorrei più informazioni per partecipare ai campionati Esport.
"
        />

        <button type="submit">Invia</button>
      </Container>
    </form>
  );
}
