import styled from "styled-components";
import { BgContainer } from "../components/bgContainer";
import { FindServices } from "./welcome";
import { RaceButton } from "../components/button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: "Handel Gothic D";
  text-align: center;
  color: var(--violet-color);

  h1 {
    font-size: 50px;

    max-width: 700px;
    text-align: center;
  }
  p {
    margin-top: 0;
    padding: 0;
  }
  button {
    background-color: var(--violet-color);
    height: 50px;
    margin-bottom: 50px;
  }
`;
const InnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export function Services() {
  return (
    <Container>
      <h1>I NOSTRI SERVIZI</h1>
      <p>Clicca e scopri cosa puoi fare al raceLAB</p>
      <BgContainer $bgImage="/home/services.webp">
        <InnerContainer>
          <a href="/ourservices">
            <RaceButton>PROVA IL SIMULATORE</RaceButton>
          </a>
        </InnerContainer>
      </BgContainer>
    </Container>
  );
}

export function ChampionsFuture() {
  return (
    <Container>
      <h1 style={{ marginBottom: "30px", marginTop: "50px" }}>
        “WE TRAIN THE CHAMPIONS OF THE FUTURE.”
      </h1>
      <BgContainer $bgImage="/home/trainchampions.webp" />
    </Container>
  );
}
