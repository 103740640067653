import styled, { keyframes } from "styled-components";
const showAnim = keyframes`
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
`;
const hideAnim = keyframes`
  from {
    margin-left: 0%;
  }
  to {
    margin-left: 100%;
  }
`;
export const TopBarMenu = styled.div`
  width: 100vw;
  user-select: none; /* Standard syntax */

  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10;
  padding: 150px;
  font-size: 45px;
  font-family: "Handel Gothic D";
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    padding-left: 20px;
    padding-top: 100px;
    font-size: 30px;
  }
  & > div {
    margin-top: 10px;

    cursor: pointer;
    &:hover {
      color: rgb(83, 137, 132);
    }
  }
  &.show > div {
    margin-top: 10px;
    animation: ${showAnim} 500ms ease forwards; /* Apply the animation */
  }
  &.hide > div {
    margin-top: 10px;
    animation: ${hideAnim} 500ms ease forwards; /* Apply the animation */
  }
  &.show {
    animation: ${showAnim} 500ms forwards; /* Apply the animation */
  }
  &.hide {
    animation: ${hideAnim} 500ms forwards; /* Apply the animation */
  }
  &.initial {
    margin-left: 100%;
  }
`;
