import styled from "styled-components";

export const TitledInput = styled.div`
  font-size: 30px;
  @media (max-width: 900px) {
    font-size: 20px;
  }
  & > input {
    margin-top: 20px;
    width: 100%;
    border: 0;
    height: 30px;
    @media (max-width: 900px) {
      height: 20px;
    }
    background-color: transparent;
    border-bottom: 3px solid var(--violet-color);
    color: white;
    font-size: 20px;
    &:focus {
      outline: none;
    }
  }
`;
export const SubmitButton = styled.button`
  border-width: 0px;
  background-color: rgb(129, 53, 138);
  font-family: "Handel Gothic D";
  color: white;
  padding: 15px;
  &:disabled {
    background-color: gray;
  }
`;
export const BecomePilot = styled.div`
  font-family: "Handel Gothic D";
  font-weight: bold;
  display: flex;
  height: auto;
  min-height: 700px;
  background-color: black;
  padding: 0px 50px 0px 50px;
  margin-top: 50px;
  @media (max-width: 800px) {
    padding: 0px 20px 0px 20px;
    margin-top: 30px;
  }
  flex-direction: column;
  justify-content: space-evenly;
  & > h1 {
    font-size: 50px;
    text-align: center;
    @media (max-width: 900px) {
      font-size: 35px;
    }
  }
`;
export const CustomRadio = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--violet-color);
  &::before {
    content: "";
    display: block;
    margin-top: -10px;
  }
  &:checked::before {
    color: white;
    font-size: 30px;
    content: "✓";
  }
`;
