import React, { useEffect, useState } from "react";
import { FormData } from "./asdRegister";
import { Document as Viewer, pdfjs, Page as Pg } from "react-pdf";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { SubmitButton } from "./components";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontSize: 12,
    padding: 40,
    fontFamily: "Courier",
    whiteSpace: "nowrap",
  },
  section: {},
  title: {
    textAlign: "center",
    margin: 10,
    marginTop: 40,

    fontSize: 20,
  },
  boldText: {
    marginTop: 30,
    fontFamily: "Courier-Bold",
  },

  img: {
    margin: "10 0",
    width: 100,
    height: "auto",
    maxHeight: 100,
    objectFit: "scale-down",
    left: 0,
  },
});
// Create Document Component
const MyDocument = (props: { form: FormData; signature: string | false }) => {
  const { form } = props;
  const isUnderage = props.form.PARENT_NAME ? true : false;
  console.log(props.form.PARENT_NAME);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Racelab ASD</Text>
          <Text>
            Via Juan Manuel Fangio snc "La Pista Centro di Guida sicura Aci"
          </Text>
          <Text>Lainate (MI) 20045</Text>
          <Text>CF 93558120155</Text>
          <Text style={{ marginBottom: 40 }}>info@racelab.it</Text>
          <Text
            style={{
              fontFamily: "Courier-Bold",
              marginBottom: 10,
              textAlign: "center",
            }}
          >
            {isUnderage
              ? "MODULO ISCRIZIONE ASSOCIATI MINORENNI PRESSO RACELAB ASD"
              : "MODULO ISCRIZIONE ASSOCIATI PRESSO RACELAB ASD"}
          </Text>

          {isUnderage && (
            <>
              <Text>{`Il/la sottoscritto/a: ${form.PARENT_NAME} ${form.SURNAME}, nato/a: ${form.PARENT_BIRTHPLACE} il: ${form.PARENT_BIRTHDAY}`}</Text>
              <Text>{`Codice Fiscale: ${form.PARENT_CODE}`}</Text>
              <Text>{`Residente a: ${form.PARENT_RESIDENCE_CITY} in ${form.PARENT_RESIDENCE_ADDRESS} `}</Text>
              <Text>{`Tel: ${form.PARENT_CEL}, Email: ${form.PARENT_EMAIL} `}</Text>
              <Text
                style={{
                  fontFamily: "Courier-Bold",
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                In qualità di genitore di
              </Text>
            </>
          )}

          <Text>{`${isUnderage ? "" : "Il/la sottoscritto/a: "}${form.NAME} ${
            form.SURNAME
          }, nato/a: ${form.BIRTHPLACE} il: ${form.BIRTHDAY}`}</Text>
          <Text>{`Codice Fiscale: ${form.CODE}`}</Text>

          <Text>{`Residente a: ${form.RESIDENCE_CITY} in ${form.RESIDENCE_ADDRESS} `}</Text>
          <Text>{`Tel: ${form.CEL}, Email: ${form.EMAIL} `}</Text>
          <Text style={styles.title}>
            {isUnderage
              ? "CHIEDE DI AMMETTERE IL/LA FIGLIO/A MINORE"
              : "CHIEDE DI ESSERE AMMESSO/A"}
          </Text>
          <Text>
            Come associato ordinario dell’Associazione Sportiva Racelab ASD
          </Text>

          <Text>
            Dichiara di aver preso visione dello Statuto, del Regolamento, e di
            accettarne il contenuto.
          </Text>
          <Text style={styles.boldText}>
            CONSENSO AL TRATTAMENTO DEI DATI PERSONALI RIGUARDANTIL’ASSOCIAZIONE
            SPORTIVA
          </Text>
          <Text>
            Con la presente dichiaro di aver ricevuto l’informativa sui diritti
            connessi al trattamento dei miei dati personali da parte
            dell’associazione, trattamento finalizzato alla gestione del
            rapporto associativo e all’adempimento di ogni obbligo di legge.
            Presto pertanto il consenso al trattamento dei miei dati per le
            finalità sopra descritte ai sensi della legge sulla privacy.
          </Text>
          <Text style={styles.boldText}>
            AUTORIZZAZIONE ALL’UTILIZZO DELL’IMMAGINE
          </Text>
          <Text>
            Con la presente {isUnderage ? "io genitore " : ""}esprimo inoltre il
            mio consenso alla pubblicazione, in qualsiasi forma, di immagini che
            {isUnderage ? "ritraggano mio figlio " : "mi ritraggono"} nello
            svolgimento delle attività associative, purché la pubblicazione non
            avvenga per perseguire finalità di natura economica.
          </Text>

          <Text style={styles.boldText}>FIRMA</Text>
          {props.signature && (
            <Image src={props.signature} style={styles.img}></Image>
          )}
          <Text>{new Date().toLocaleDateString("en-GB")}</Text>
        </View>
      </Page>
    </Document>
  );
};
export function ViewPdf(props: {
  form: FormData;
  signature: string | false;
  onChange: (string) => void;
}) {
  const [pdfUrl, setPdfUrl] = useState<string>("");
  useEffect(() => {
    const doc = <MyDocument {...props} />;
    async function BlobToBase64() {
      const blob = await pdf(doc).toBlob();
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        let base64String = reader.result;
        props.onChange(base64String);
        setPdfUrl(base64String as string);
      };
    }
    BlobToBase64();
  }, [props.form, props.signature]);
  return (
    <>
      <Viewer file={pdfUrl} className={"pdf"}>
        <Pg
          pageNumber={1}
          className={"pdfPage"}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          canvasBackground="none"
          width={window.innerWidth - 40}
        />
      </Viewer>
      {props.signature && (
        <PDFDownloadLink
          document={<MyDocument {...props} />}
          fileName="Registrazione RaceX.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <SubmitButton type="button" style={{ margin: "30px 0" }}>
                Scarica il modulo firmato
              </SubmitButton>
            )
          }
        </PDFDownloadLink>
      )}
    </>
  );
}
