import styled from "styled-components";
import { BuyButton, RaceButton } from "../../components/button";
import { useGetProductByIdQuery } from "../../generated/graphql";
const SectionTitle = styled.div`
  font-family: "Handel Gothic D";
  font-weight: bold;
  font-style: normal;
  font-size: 1.3em;
  color: #ffffff;
  text-align: center;
`;
const Day = styled.div`
  font-family: "Handel Gothic D";
`;
const DayButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const DayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export function TestSession() {
  const { data, loading, error } = useGetProductByIdQuery({
    variables: { id: "gid://shopify/Product/9237562425655" },
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <SectionTitle>TEST SESSION DINAMIC SIMULATOR</SectionTitle>
      <p>
        {`Prenota la tua sessione e presentati al LAB con l’email di conferma del pagamento.Durante la sessione di prova di 10 minuti potrai sperimentare il nostro metodo di allenamento grazie al supporto dei coach e degli ingegneri che ti guideranno come se fossi in pista.Il nostro approccio è formare tutti coloro che vogliano affacciarsi al mondo del sim racing come dei professionisti: al raceLAB troverai massima professionalità e la tua stessa passione per i motori.`}
      </p>
      <p>
        {`Non è prevista la prenotazione della fascia oraria.
Al tuo arrivo ti verrà comunicato lo slot e il coach a te assegnato.

Sarà disponibile una navetta dalla fermata della metro RHO FIERA.`}
      </p>

      {!loading && (
        <BuyButton id="gid://shopify/ProductVariant/48144482206007">
          <>
            {" "}
            Acquista ora{" "}
            {parseFloat(data?.product.variants.nodes[0].price.amount).toFixed(
              0
            )}{" "}
            €
          </>
        </BuyButton>
      )}
    </>
  );
}

function RaceSlots(props: { id: string }) {
  const { data, loading, error } = useGetProductByIdQuery({
    variables: { id: props.id },
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {!loading &&
        data?.product.variants.nodes.map((variant) => (
          <BuyButton
            className="numeric"
            id={variant.id}
            disabled={!variant.availableForSale}
          >
            <> {variant.title}</>
          </BuyButton>
        ))}
    </>
  );
}

export function DinamicSession() {
  return (
    <>
      <SectionTitle>30 MIN DYNAMIC SIMULATOR</SectionTitle>
      <p>
        {`Ogni sessione prenotata al LAB prevede il supporto tecnico di uno dei nostri coach, questo per essere certi che, qualsiasi sia il tuo livello, tu possa vivere la  la miglior esperienza possibile sui nostri sim e comprendere quale sia il nostro metodo di allenamento. 

Scegli la fascia oraria che preferisci e potrai fare 30 minuti a bordo del nostro simulatore dinamico.`}
      </p>

      <Day>Sabato 16 Marzo</Day>

      <RaceSlots id="gid://shopify/Product/9241908904247" />
      <br />
      <br />
      <Day>Domenica 17 Marzo</Day>

      <RaceSlots id="gid://shopify/Product/9241922601271" />
    </>
  );
}

function RealCarsSection(props: { id: string; title: string; desc: string }) {
  const { data, loading, error } = useGetProductByIdQuery({
    variables: { id: props.id },
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <SectionTitle>{props.title}</SectionTitle>
      <p>{props.desc}</p>
      <DayContainer>
        {!loading &&
          data.product.variants.nodes.map((variant) => (
            <DayButton>
              <Day>{variant.title}</Day>
              <BuyButton id={variant.id} key={variant.id}>
                <>
                  {`Acquista ora  ${parseFloat(variant.price.amount).toFixed(
                    0
                  )}`}
                  €
                </>
              </BuyButton>
            </DayButton>
          ))}
      </DayContainer>
    </>
  );
}

export function SportCarSection() {
  return (
    <RealCarsSection
      id="gid://shopify/Product/9241992528183"
      title="SPORT CAR 3 LAPS"
      desc={`Le nostre esperienze di guida in pista iniziano al volante di performanti auto stradali come mercedes A45 AMG e Audi RS3.

      Guiderai sul nostro circuito di casa [La Pista], affiancato da un istruttore di guida professionista, che ti aiuterà ad interpretare al meglio i primi passi in pista.
      
      L'esperienza sarà registrata cosi che vi sarà fornito un video completo dell'esperienza.
      
      Prima di ogni sessione di guida, svolgeremo assieme agli istruttori un briefing teorico per fornire le tecniche di guida di base, poi si passerà al simulatore per verificare il livello e per mettere in pratica le tecniche di guida sicura e veloce in pista.
      
      Le sessioni in pista avvengono dalle 16.00 alle 18.00 e richiedono l’accredito in struttura un’ora prima del proprio turno.
      Effettuato il pagamento riceverai una mail con l'orario di convocazione in pista, l'orario indicato sarà quello in cui presentarti per l'accredito, il briefing teorico, la sessione al simulatore ed infine la tua esperienza in pista.
  
  
  Arrivo al raceLAB 1 ora prima della sessione prenotata.
  Sarà disponibile una navetta dalla fermata della metro RHO FIERA.`}
    />
  );
}

export function SuperCarSection() {
  return (
    <RealCarsSection
      id="gid://shopify/Product/9242004390199"
      title="SUPERCAR 3 LAPS"
      desc={`Le nostre esperienze di guida in pista continuano con supercar stradali Ferrari o Lamborghini.

      Guiderai sul nostro circuito di casa [La Pista], affiancato da un istruttore di guida professionista, che ti aiuterà ad interpretare al meglio i primi passi in pista.
      L'esperienza sarà registrata cosi che vi sarà fornito un video completo dell'esperienza.
      
      Prima di ogni sessione di guida, svolgeremo assieme agli istruttori un briefing teorico per fornire le tecniche di guida di base, poi si passerà al simulatore per verificare il livello e per mettere in pratica le tecniche di guida sicura e veloce in pista.
      Le sessioni in pista avvengono dalle 16.00 alle 18.00 e richiedono l’accredito in struttura un’ora prima del proprio turno.
      
      Effettuato il pagamento riceverai una mail con l'orario di convocazione in pista, l'orario indicato sarà quello in cui presentarti per l'accredito, il briefing teorico, la sessione al simulatore ed infine la tua esperienza in pista.
      
  Arrivo al raceLAB 1 ora prima della sessione prenotata.
  Sarà disponibile una navetta dalla fermata della metro RHO FIERA.`}
    />
  );
}

export function RacingCarSection() {
  return (
    <RealCarsSection
      id="gid://shopify/Product/9242005340471"
      title="SUPERCAR 3 LAPS"
      desc={`Avrai la possibilità di salire su una vera macchina da corsa, come una Radical o una Mitjet, e vivere al fianco di un pilota professionista la vera esperienza ed il brivido di scendere in pista!

      Sarai inoltre collegato via radio con il pilota, il quale ti spiegherà il metodo con cui approccia la guida sportiva svelandoti alcune tecniche di guida avanzate.
      
      La tua esperienza sarà registrata cosi che potrai riviverla e carpire ogni volta qualcosa di nuovo.
      I nostri piloti sono Alberto Di Folco (Pilota Lamborghini) Pierandrea De Marco (Pilota Mitjet) e dulcis in fundo, Giancarlo Fisichella!
      
      
  Arrivo al raceLAB 1 ora prima della sessione prenotata.
  Sarà disponibile una navetta dalla fermata della metro RHO FIERA.`}
    />
  );
}
export function VisitorSection() {
  return (
    <RealCarsSection
      id="gid://shopify/Product/9252771299639"
      title="VISITORS"
      desc={`Ogni visitatore può accedere gratuitamente al nostro evento previa registrazione, il braccialetto rosso consentirà l’accesso e la presenza durante la nostra inaugurazione, alle sessioni Meet & Greet con piloti professionisti del calibro di Giancarlo Fisichella, che sarà presente il 16 ed il 17 Marzo. Inoltre gli spettatori registrati potranno assistere dal vivo al training di veri piloti professionisti ed acquistare in loco tutte le esperienze sul simulatore ed in pista offerte online da raceX`}
    />
  );
}
