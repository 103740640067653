import styled from "styled-components";
import { BgContainer } from "../../components/bgContainer";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useState, useEffect } from "react";
import { AsdRegister } from "./asdRegister";
import { SubmitButton, TitledInput } from "./components";
import { BecomePilot } from "./components";
import { CustomRadio } from "./components";
import { RaceButton } from "../../components/button";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-content: center;
  align-items: center;
  justify-items: stretch;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 900px) {
    font-size: 15px;
    grid-template-columns: 1fr;
    padding-left: 10px;
    padding-right: 10px;
  }

  & > div:first-child > h1 {
    font-family: "Handel Gothic D";
    font-weight: bold;
    font-size: 50px;
  }
`;

function PilotRegister() {
  const [formData, setFormData] = useState({
    FNAME: "",
    LNAME: "",
    EMAIL: "",
    MMERGE3: "",
  });

  return (
    <MailchimpSubscribe
      url={
        "https://racelab.us13.list-manage.com/subscribe/post?u=cc0217a0fef5b9a254924a2b1&amp;id=b614fc09c0&amp;f_id=00a5b8e5f0"
      }
      render={({ subscribe, status, message }) => (
        <BecomePilot>
          <h1>Registrati alla newsletter</h1>
          <TitledInput>
            Nome
            <input
              onChange={(val) =>
                setFormData({ ...formData, FNAME: val.target.value })
              }
              value={formData.FNAME}
            />
          </TitledInput>
          <TitledInput>
            Cognome
            <input
              onChange={(val) =>
                setFormData({ ...formData, LNAME: val.target.value })
              }
              value={formData.LNAME}
            />
          </TitledInput>
          <TitledInput>
            Email*
            <input
              type="email"
              onChange={(val) =>
                setFormData({ ...formData, EMAIL: val.target.value })
              }
              value={formData.EMAIL}
            />
          </TitledInput>
          Hai mai provato un simulatore di guida professionale?*
          <label>
            <CustomRadio
              onChange={() => setFormData({ ...formData, MMERGE3: "YES" })}
              type="radio"
              name="radio"
            />
            Si
          </label>
          <label>
            <CustomRadio
              onChange={() => setFormData({ ...formData, MMERGE3: "NO" })}
              type="radio"
              name="radio"
            />
            No
          </label>
          {status !== "success" ? (
            <SubmitButton
              onClick={() => subscribe(formData)}
              disabled={formData.EMAIL === "" || formData.MMERGE3 === ""}
            >
              {status === "sending" ? "Sto Inviando" : "Invia"}
            </SubmitButton>
          ) : (
            <>{message}</>
          )}
        </BecomePilot>
      )}
    />
  );
}
export function DiventaPilota() {
  return (
    <BgContainer
      gradient
      $bgImage="./lapista.webp"
      id="pilot"
      style={{ height: "100%" }}
    >
      <Container>
        <div>
          <h1>Registrati e diventa parte della squadra</h1>
          Così avrai l’occasione di partecipare a contest esclusivi dedicati
          solo agli associati.
          <br />
          <br />
          <a href="/registerasd">
            <RaceButton>Registrati all' ASD</RaceButton>
          </a>
          <br />
          Disponibile solo da mobile
        </div>
        <PilotRegister />
      </Container>
    </BgContainer>
  );
}
