import styled from "styled-components";
import { useScroll, animated } from "@react-spring/web";
import { useEffect, useRef, useState } from "react";
import { slideTop, logoAnimation } from "./animations";
import { TopBarMenu } from "./topBarMenu";
import { RaceButton } from "./button";
import { Link, useLocation } from "react-router-dom";
const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

const Container = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  justify-content: space-between;
  background-color: rgb(0, 0, 0, 0);
  width: 100%;
  position: fixed;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 3;
  &.show {
    z-index: 5;
  }
  box-sizing: border-box;
  min-height: 110px;
  top: 0;
  @media (max-width: 800px) {
    min-height: 100px;
    grid-template-columns: 100px 1fr 100px;
  }
`;

const MenuButton = styled.div`
  width: 100px;
  height: 10px;
  grid-row: 1 / 2;
  margin-top: 40px;
  border-top: 10px solid black;
  border-bottom: 10px solid black;
  cursor: pointer;
  grid-column: 1;
  z-index: 15;
  border-color: white;
  @media (max-width: 800px) {
    width: 70px;
    height: 7px;
    border-width: 7px;
    margin-top: 30px;
  }
`;
const EnrollButton = styled(RaceButton)`
  width: 150px;
  height: 47px;
  grid-row: 1/2;
  margin-top: 18px;
  z-index: 15;
  grid-column: 3;

  z-index: 5;

  grid-column: 3;
  @media (max-width: 800px) {
    width: 100px;
    padding: 10px;
  }
`;

const Logo = styled.div`
  align-self: center;
  position: relative;
  justify-self: center;
  background-image: url("/logoracex.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-row: 1 / 2;
  grid-column: 1/4;
  margin-top: 20px;

  animation: ${slideTop} 1300ms ease forwards; /* Apply the animation */
  @media (max-width: 800px) {
    max-width: 250px;
    max-height: 250px;
  }
  &::after {
    content: "";
    position: absolute;

    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    opacity: 1;

    z-index: 3;

    background-image: linear-gradient(
        to right,
        transparent var(--gradientTransparent),
        black 0%,
        black var(--gradientBlack),
        transparent 0%
      ),
      var(--bgLogoText);
    animation: ${logoAnimation} 600ms 1s forwards; /* Apply the animation */

    top: 89%;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export function TopBar(props: { fixed?: boolean }) {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [scrollVal, setScrollVal] = useState(0);
  const logoHeight = windowSize.current[0] > 800 ? 280 : 300;
  const [showMenu, setMenu] = useState<"initial" | "show" | "hide">("initial");
  const location = useLocation();
  useScroll({
    onChange: ({ value: { scrollY } }) => {
      setScrollVal(scrollY * 1.5);
    },
  });
  useEffect(() => {
    showMenu === "show" && setMenu("hide");
  }, [location]);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      setMenu("hide");
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const styleContainerAnim = {
    backgroundColor: `rgba(0,0,0,${
      scrollVal >= windowSize.current[1] * 0.8
        ? scrollVal / windowSize.current[1]
        : 0
    })`,
    height: windowSize.current[1] - clamp(scrollVal, 0, windowSize.current[1]),
  };

  return (
    <Container
      className={
        showMenu === "show" || scrollVal >= windowSize.current[1] * 0.8
          ? "show"
          : "hide"
      }
      as={animated.div}
      style={!props.fixed ? styleContainerAnim : { backgroundColor: "black" }}
    >
      <MenuButton
        onClick={() => setMenu(showMenu === "show" ? "hide" : "show")}
      />
      <TopBarMenu className={showMenu}>
        <a href="/#welcome">
          <div>Home</div>
        </a>

        <a href="/ourservices">
          <div>Prova il simulatore</div>
        </a>
        <a href="/trysim">
          <div>I Nostri servizi</div>
        </a>
        <a href="/#pilot">
          <div>NEWSLETTER</div>
        </a>
        <a href="/#chi_siamo">
          <div>Chi Siamo</div>
        </a>
        <a href="/timeAttack">
          <div>Registrati a Time Attack Championship</div>
        </a>
        <a href="/#contact">
          <div>contattaci</div>
        </a>
        <Link to="/shop" id=""></Link>
      </TopBarMenu>
      <Logo
        as={animated.div}
        style={
          !props.fixed
            ? {
                height: 360 - clamp(scrollVal, 0, logoHeight),
                width: 360 - clamp(scrollVal, 0, logoHeight),
              }
            : { height: 360 - logoHeight, width: 360 - logoHeight }
        }
      />
      <EnrollButton onClick={() => scrollToSection("pilot")}>
        Newsletter
      </EnrollButton>
    </Container>
  );
}
