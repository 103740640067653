import styled from "styled-components";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";

export const FooterLogos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  max-height: 150px;
  grid-template-rows: 1fr 1fr 1fr;
  margin-bottom: 30px;
  margin-top: 30px;
  column-gap: 20px;
`;

export const Container = styled.div`
  padding: 30px;
  white-space: pre-line;
  font-size: 15px;
  word-spacing: 3px;
  text-align: center;
  @media screen and (max-width: 800px) {
    padding: 5px;
  }
`;
export const SpacedIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  margin-left: 10px;
`;

export const Logo = styled.div<{ $logoImg?: string }>`
  background-image: url(${(props) => props.$logoImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
`;

export function Footer() {
  return (
    <Container>
      <FooterLogos>
        <Logo $logoImg="/loghi/simulator.webp" />
        <Logo $logoImg="/loghi/aci.webp" />
        <Logo $logoImg="/loghi/s4.webp" />
        <Logo $logoImg="/loghi/pista.webp" />

        <Logo $logoImg="/loghi/mindup.webp" />
        <Logo $logoImg="/loghi/athletica.webp" />
      </FooterLogos>
      <div>
        <p>
          <SpacedIcon icon={faYoutube} />
          <a href="https://www.youtube.com/@RaceXDrivingSuccess">{`RaceXDrivingSuccess`}</a>
          <SpacedIcon icon={faInstagram} />
          <a href="https://www.instagram.com/____racex">{`____racex`}</a>
        </p>
        <p>
          RaceLab ASD, CF: 93558120155 <wbr /> Via Juan Manuel Fangio snc,
          Lainate (MI), 20045 <wbr /> asd@racelab.pec.it info@racelab.it
        </p>
        <p>
          ORARI: Lunedì - Venerdì 12:00 - 22:00 | Sabato - Domenica 10:00 -
          22:00
        </p>
      </div>
    </Container>
  );
}
