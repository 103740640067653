export function AgreementDoc() {
  return (
    <div
      style={{
        fontFamily: "helvetica",
        fontSize: "0.5em",
        padding: "10px",
      }}
    >
      <>
        <p>
          <strong>ALLEGATO "A"</strong>
        </p>
        <p>
          <strong>STATUTO</strong>
        </p>
        <p>
          <strong>Articolo primo: </strong>è costituita, nel rispetto del
          Decreto Legislativo 28 febbraio 2021, n. 36 e ss.mm.ii. e della
          normativa in materia, una Associazione Sportiva Dilettantistica non
          riconosciuta, senZa scopo di lucro, apartitica, apolitica,
          aconfessionale, denominata <strong>RACELAB ASD</strong>
        </p>
        <p>
          La denominazione di "Associazione Sportiva Dilettantistica" nonché il
          relativo acronimo di "ASD", potrà essere inserito nella denominazione
          in via automatica e sarà spendibile nei rapporti con i terzi, negli
          atti, nella corrispondenza e nelle comunicazioni con il pubblico solo
          dopo aver ottenuto l’iscrizione nel Registro Nazionale delle Attività
          Sportive Dilettantistiche e limitatamente in costanza di iscrizione
          nel Registro medesimo.
        </p>
        <p>
          L’Associazione si obbliga a conformarsi alle norme e alle direttive
          del Comitato Internazionale Olimpico (CIO), del Comitato Olimpico
          Nazionale Italiano (CONI), del Comitato Italiano Paralimpico (CIP),
          nonché agli statuti e ai regolamenti dell’ Ente di Promozione Sportiva
          cui l’Associazione medesima intende affiliarsi e di cui riconosce la
          giurisdizione sportiva e disciplinare. Previo riconoscimento ai fini
          sportivi da parte dell’Ente di Promozione Sportiva affiliante,
          l’Associazione si iscrive al Registro nazionale delle attività
          sportive dilettantistiche.
        </p>
        <p>
          L’Associazione, nel perseguimento delle proprie finalità sportive,
          creative e culturali, si ispira al principio democratico di
          partecipazione all’attività sportiva dilettantistica e di promozione
          sociale da parte di tutti in condizioni di uguaglianza e di pari
          opportunità, attraverso la democraticità della struttura,
          l’uguaglianza dei diritti di tutti gli Associati e l’elettività delle
          cariche associative. L’Associazione opera per l’esclusivo
          soddisfacimento di interessi collettivi, e non discrimina in base al
          sesso, alla religione, alla razza ed alle condizioni socio-economiche.
        </p>
        <p>&nbsp;</p>
        <p>SEDE</p>
        <p>
          <strong>Articolo secondo: </strong>la Sede Legale dell’ Associazione,
          sarà stabilita a Lainate (Mi), in Via Juan Manuel Fangio Snc e potrà
          istituire sedi secondarie ed impianti distaccati per la pratica
          dell’attività sportiva. La modifica della Sede Legale
          dell’Associazione nell’ambito dello stesso Comune, può essere adottata
          con delibera dell’Assemblea degli Associati senza dover procedere a
          modifiche dello Statuto.
        </p>
        <p>
          <strong>Articolo terzo: </strong>l'Associazione non ha scopo di lucro
          e ha ad oggetto:
        </p>
        <p>
          -&nbsp;&nbsp; l’esercizio dell’organizzazione e gestione di attività
          sportive dilettantistiche con particolare riferimento alle discipline
          sportive riferite alla guida sportiva di auto su pista, anche mediante
          l’uso di simulatori di guida professionali, ivi comprese la
          formazione, la didattica, la preparazione e l’assistenza all’attività
          sportiva dilettantistica, ai sensi dell’art.7 del D.Lgs.n.36/2021,
        </p>
        <p>
          -&nbsp;&nbsp;&nbsp; la partecipazione, con i propri Associati, a
          manifestazioni motoristiche sportive, anche in forma di campionati
          italiani ed europei, trofei o singole gare;
        </p>
        <p>
          -&nbsp;&nbsp;&nbsp; promuovere l’esercizio di attività sportive
          dilettantistiche, mediante iniziative finalizzate al miglioramento
          fisico e psichico della persona, anche mediante macchinari specifici
          da fitness,
        </p>
        <p>
          -&nbsp;&nbsp;&nbsp; l’organizzazione di attività sportive
          dilettantistica nel campo dell’automobilismo, del karting e del
          motorismo sportivo in genere;
        </p>
        <p>
          -&nbsp; l’organizzazione di manifestazioni, corsi di guida e convegni
          aventi per oggetto la sicurezza stradale, la guida e la prova di
          veicoli sportivi a motore e/o di simulatori di guida professionali;
        </p>
        <p>
          -l’organizzazione di competizioni, manifestazioni o trofei tra gli
          Associati, mediante l’uso di simulatori di guida professionali tra
          essi collegati;
        </p>
        <p>
          -&nbsp;&nbsp; la divulgazione e la promozione del motorismo sportivo
          in ogni sua forma, la partecipazione a competizioni, gare, mostre,
          fiere e manifestazioni sportive in genere,
        </p>
        <p>
          -&nbsp;&nbsp; l’organizzaZione e la gestione di manifestazioni
          sportive, riservate agli Associati, finalizzate all’uso, presso
          Autodromi, strade chiuse o strutture adeguate, di mezzi a motore o
          elettrici, per giri di pista liberi e/o prove a tempo;
        </p>
        <p>
          -&nbsp; la gestione , l’uso di autovetture e/o vetture prototipo da
          corsa e/o di go-kart per competizioni sportive presso autodromi, gare
          in Salita e/o Rally,
        </p>
        <p>
          ricevere sponsorizzazioni sportive da aziende private col fine di
          sostenere i costi riferiti all’attività sportiva dilettantistica
          dell’Associazione, in diretta attuazione con gli scopi statutari e
          istituzionali dell’Ente,
        </p>
        <p>
          -&nbsp; la gestione finalizzata all’uso, da parte dei propri
          Associati, di simulatori di guida, con lo scopo di avvicinare giovani
          e meno giovani al motorismo sportivo;
        </p>
        <p>
          -&nbsp;&nbsp;&nbsp; l’attuaZione di programmi educativi per bambini e
          ragazzi, complementari allo sport, con approfondimenti anche di
          materie propriamente tipiche della didattica scolastica;
        </p>
        <p>
          -&nbsp; il poter trasportare sui campi gara, con mezzi di trasporto
          propri, noleggiati o prestati, autoveicoli da corsa, curandone pure
          l’assistenza meccanica e lo sviluppo tecnico-sportivo,
        </p>
        <p>
          -&nbsp; il poter svolgere test e prove di messa a punto di mezzi a
          motore di vario genere, utilizzati nelle competizioni e non, in
          preparazione a gare o manifestazioni al quale partecipare con i propri
          Associati;
        </p>
        <p>
          -l’organizzazione, la gestione e lo svolgimento di corsi di educazione
          stradale o di perfezionamento alla guida di auto e go-kart, tra cui ad
          esempio corsi di guida sicura, guida sportiva, guida agonistica con
          ogni genere di veicolo a motore, sia esso adibito alla circolazione
          stradale o all’impiego agonistico;
        </p>
        <p>
          l’organizzazione di attività sportive dilettantistiche, compresa
          l’attività didattica per l’avvio, l’aggiornamento e il perfezionamento
          nelle attività di pilotaggio di simulatori di guida e mezzi a motore,
          rivolti agli Associati di ogni genere ed età;
        </p>
        <p>
          -&nbsp; la collaborazione, intesa anche come assistenza sportiva dei
          propri Associati sui campi gara, durante le manifestazioni con
          Federazioni di promozione sportiva o Associazioni con scopo analogo e
          durante manifestazioni motoristico-sportive in genere,
        </p>
        <p>
          -la diffusione gratuita di materiale informativo sulle iniziative ed i
          progetti dell'Associazione;
        </p>
        <p>
          -&nbsp; la promozione, la diffusione, il coordinamento e la pratica a
          livello dilettantistico delle attività sportive, intese come strumento
          di sviluppo e di formazione sportiva e psicofisica dell’Associato, in
          particolar modo della disciplina del motorismo sportivo;
        </p>
        <p>
          -&nbsp; la creazione di riunioni, meeting, dibattiti culturali sul
          mondo del Motorsport;
        </p>
        <p>
          -&nbsp;&nbsp; l’organizzazione di attività accessorie e complementari
          alle attività sportive;
        </p>
        <p>
          -&nbsp;&nbsp; la gestione di impianti sportivi (Autodromi,
          Kartodromi,.:..), anche in collaborazione con terzi, di proprietà di
          Enti Pubblici, di Enti Sportivi o di Soggetti Privati,
        </p>
        <p>
          -&nbsp;&nbsp;&nbsp; l’organizzazione di eventi culturali legati alle
          attività sportive;
        </p>
        <p>
          -&nbsp; praticare l'adesione ad altri Enti di Promozione Sportiva
          (CONI, ACSI, UISP ecceteral, Federazioni e/o Organismi aventi scopi e
          finalità analoghe, conformandosi alle recenti Norme ed alle direttive
          del Coni riferite alle Associazioni Sportive Dilettantistiche:
        </p>
        <p>
          -&nbsp; promuovere, in ambito associativo sportivo, attività ludiche e
          didattiche con laboratori mirati;
        </p>
        <p>
          -&nbsp; esercitare, a norma dell’art. 9 del D.lgs. n. 36/2021,
          attività diverse da quelle principali, purché secondarie e strumentali
          rispetto a queste ultime, secondo criteri e limiti definiti con
          apposito Decreto. La loro individuazione è operata da parte
          dell’Organo di Amministrazione;
        </p>
        <p>
          -&nbsp; creare all’interno dei locali o degli spazi destinati
          all’attività Sportiva dell’Associazione e/o presso la Sede della
          stessa, uno spazio dedicato ai minori presenti nella struttura;
        </p>
        <p>
          -&nbsp; la diffusione gratuita di materiale informativo sulle
          iniziative ed i progetti dell'Associazione;
        </p>
        <p>
          -&nbsp; il curare in proprio o per mezzo di adeguate strutture, anche
          esterne, la pubblicazione di libri, la distribuzione di volantini,
          dispense, periodici, volumi, monografie, affissioni etc., svolgere
          attività di ricerca in ambito culturale educativo e gestire archivi di
          documentazione,
        </p>
        <p>
          istituire in locali eventualmente all'uopo acquisiti, una biblioteca
          ed un archivio di documentazione culturale e sportiva a disposizione
          degli Associati;
        </p>
        <p>
          -&nbsp; promuovere la raccolta di fondi, attraverso il contributo
          degli Associati ed ogni altra fonte di contribuzione:
        </p>
        <p>
          -&nbsp;&nbsp;&nbsp; promuovere e/o curare l'organizzazione di
          manifestazioni, che abbiano lo scopo finale di cui ai punti
          precedenti.
        </p>
        <p>DURATA</p>
        <p>
          Articolo quarto: la durata del1'Associazione viene stabilita a tempo
          indeterminato; la stessa potrà essere sciolta unicamente con delibera
          dell’Assemblea Straordinaria.
        </p>
        <p>Articolo quinto: il patrimonio dell’Associazione è costituito:</p>
        <p>
          a)&nbsp;&nbsp;&nbsp; dal Capitale iniziale, versato dagli Associati
          Fondatori,
        </p>
        <p>
          b)&nbsp;&nbsp;&nbsp; dai contributi di ammissione e da quelli
          straordinari deliberati con lo scopo di incrementare il patrimonio, da
          indicare nel bilancio annuale come Fondo contributi di ammissione
          (Quote Associative) e Fondo contributi straordinari associati (quote
          derivanti da contributi da Associati o soggetti privati-Contributi
          Sportivi),
        </p>
        <p>
          cl da eventuali donazioni, rendite patrimoniali, lasciti e
          contribuzioni straordinarie di persone fisiche ed Enti, anche
          pubblici, da indicare nel bilancio annuale come contributi
          straordinari di terzi;
        </p>
        <p>
          d)&nbsp;&nbsp;&nbsp; dai risultati derivanti dalla gestione, se non
          diversamente deliberato, dall’Assemblea che approva il bilancio
          annuale, da indicare nello stesso come avanzo o <em>disavanzo </em>di
          gestione; tali avanzi saranno da destinarsi, come da Normativa
          vigente, allo svolgimento dell’attività statutaria o all’incremento
          del Patrimonio dell’Associazione,
        </p>
        <p>
          e)&nbsp;&nbsp;&nbsp; da ogni altra entrata in Conto Capitale che
          concorra ad incrementare il Patrimonio Sociale, da indicare nel
          bilancio annuale come Contributi Straordinari di terzi o di Associati
          in relazione alla provenienza dell’entrata,
        </p>
        <p>
          f)&nbsp;&nbsp; da tutti i beni mobili e immobili, materiali e
          immateriali pervenuti all’Associazione a qualsiasi titolo;
        </p>
        <p>
          g)&nbsp;&nbsp; da entrate derivanti da eventuali attività diverse da
          quelle principali e da entrate provenienti dall’attività di raccolta
          Fondi;
        </p>
        <p>h)&nbsp; da rimborsi da convenzioni.</p>
        <p>
          i)&nbsp; I beni dell’Associazione sono beni immobili, beni registrati
          mobili e beni mobili. I beni immobili ed i beni registrati mobili
          possono essere acquistati dall’Associazione, e sono ad essa intestati.
        </p>
        <p>
          I beni immobili, i beni registrati mobili, nonché i beni mobili che
          sono collocati nella sede dell’Associazione sono elencati nel Registro
          dei Cespiti, che è depositato presso la sede dell’Associazione e può
          essere consultato dagli Associati.
        </p>
        <p>
          Il patrimonio de1l’Associazione, comprensivo di eventuali ricavi,
          rendite, proventi ed altre entrate comunque denominate, è utilizZato
          per lo svolgimento delle attività statutarie ai fini dell’esclusivo
          perseguimento delle finalità civiche, solidaristiche e di utilità
          sociale. E’ fatto obbligo devolvere il Patrimonio, in caso di
          scioglimento dell’Associazione. I beni che residuano dopo
          l’esaurimento della liquidazione sono devoluti ad altre Associazioni
          senza scopo di lucro operanti in analogo settore, secondo le
          indicazioni dell’Assemblea. In nessun caso possono essere distribuiti
          beni, utili e riserve agli Associati.
        </p>
        <p>
          <strong>ASSOCIATI</strong>
        </p>
        <p>
          <strong>Articolo sesto: </strong>sono Associati dell’Associazione, le
          persone fisiche che condividono le finalità e gli scopi associativi e
          si impegnano per realizzare le attività di interesse generale. Gli
          Associati si distinguono in:
        </p>
        <p>
          comma l)Associati Fondatori comma 2)Associati Ordinari comma
          3)Associati Sostenitori comma 4)Associati Onorari
        </p>
        <p>comma 5)Associati Lavoratori Volontari comma 6)Associati Junior</p>
        <p>comma 7)Associato Lavoratori Sportivi</p>
        <p>
          comma 2: sono <em>Assocìatì Fondatori </em>coloro che risultano
          dall’Atto Costitutivo
          dell'Associazione.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          E’&nbsp; fatto&nbsp; divieto&nbsp; agli&nbsp; Associati Fondatori con
          cariche di amministratori facenti parte del Consiglio Direttivo, di
          ricoprire le medesime cariche in altre Associazioni o Società Sportive
          nell’ambito della medesima federazione sportiva o disciplina
          riconosciuta dal Coni, ovvero nell’ambito della medesima disciplina
          facente capo ad un Ente di promozione sportiva.
        </p>
        <p>
          comma 2: sono Associati Ordinari, senza pregiudiziali di sorta, le
          persone fisiche che, in regola con il versamento annuale della Quota
          Associativa, intendono aderire all’Associazione e perseguire con essa
          lo scopo per il quale è stata costituita, prestando anche direttamente
          e materialmente la propria opera gratuita sia manuale che
          intellettuale.
        </p>
        <p>
          comma 3: sono Associati Sostenitori coloro che, versando annualmente
          la&nbsp;&nbsp; Quota&nbsp;&nbsp; Associativa,&nbsp;
          intendono&nbsp;&nbsp; aderire&nbsp;&nbsp; all’Associazione
          condividendone lo scopo per il quale è stata costituita.
        </p>
        <p>
          comma 4: gli Associati Fondatori possono nominare degli Associati
          Onorari.
        </p>
        <p>
          Lo status di Associati Onorari comporta il diritto di partecipare alle
          Assemblee dell’Associazione.
        </p>
        <p>
          Può essere ammesso come Associato Onorario ogni persona fisica o
          giuridica che, indipendentemente dall’attività che svolge, può
          contribuire al prestigio, all’autorità ed al raggiungimento dei fini
          dell'Associazione. L’Associato Onorario è esentato dal versamento
          della Quota Associativa.
        </p>
        <p>
          comme <em>5: </em>sono Associati Lavoratori Volontari coloro che
          mettono a disposizione il proprio tempo e le proprie capacità per
          promuovere lo sport, in modo personale, spontaneo e gratuito, senza
          fini di lucro, neanche indiretti ed esclusivamente per fini di
          solidarietà. La qualità di volontario è incompatibile con qualsiasi
          forma di rapporto di lavoro subordinato o autonomo e con ogni altro
          rapporto di lavoro retribuito con l’Associazione di cui il volontario
          è socio o associato o tramite il quale svolge la propria attività
          sportiva.
        </p>
        <p>
          L’attività del volontario non puÓ essere retribuita in alcun modo,
          nemmeno dal beneficiario. Ai volontari possono essere rimborsate
          soltanto le spese effettivamente sostenute e documentate per
          l’attività prestata, entro i limiti massimi e alle condizioni
          preventivamente stabilite dall’Associazione. Sono vietati i rimborsi
          spesa di tipo forfetario.
        </p>
        <p>
          I volontari sono assicurati dall’Associazione per la responsabilità
          civile verso i terzi ai sensi dell’art. 29 del D.lgs. n. 36/2021- Si
          applica l’art. 18, comma 2 del D.lgs. n. 117/2017.
        </p>
        <p>
          comma 6: sono associati Junior gli individui con età compresa tra zero
          e 18 anni, che si iscrivono a1l’Associazione per beneficiare del
          diritto a partecipare alle iniziative sportive previste.
        </p>
        <p>
          L’ammissione all’Associazione è deliberata dal Consiglio Direttivo su
          domanda dell'interessato, secondo criteri non discriminatori, coerenti
          con le finalità perseguite e le attività d’interesse generale. La
          deliberazione è annotata nel Libro degli Associati.
        </p>
        <p>
          E’ riconosciuto il diritto di voto a tutti gli Associati maggiorenni
          in regola con il versamento del contributo associativo annuale. Alle
          Assemblee sono convocati anche gli Associati minorenni che vengono
          rappresentati, con diritto di voto, dall’esercente la potestà
          genitoriale che ha sottoscritto la domanda di ammissione ad associato
          del minore.
        </p>
        <p>
          comma 7: è un Associato Lavoratore Sportivo ogni tesserato che svolge
          verso un corrispettivo, le mansioni rientranti tra quelle necessarie
          per lo svolgimento di attività sportiva(atleta, allenatore,
          istruttore, direttore tecnico, direttore sportivo, preparatore
          atletico e direttore di gara) , con esclusione delle mansioni di
          carattere amministrativo-gestionale.
        </p>
        <p>
          Il lavoratore sportivo eserciterà, quindi, l’attività sportiva senza
          alcuna distinzione di genere, indipendentemente dal settore
          professionistico o dilettantistico.
        </p>
        <p>
          <strong>Articolo settimo: </strong>gli Associati si impegnano ad
          accettare espressamente lo Statuto dell'Associazione ed ogni altra
          norma che modifichi o integri in futuro la disciplina
          dell'Associazione stessa.
        </p>
        <p>
          Agli Associati è richiesto un comportamento tale da fornire una
          adeguata immagine di professionalità ed un impegno a non compiere atti
          che possano recare, in qualunque modo, nocumento al prestigio
          dell'Associazione.
        </p>
        <p>
          <strong>Gli Associati hanno diritto di:</strong>
        </p>
        <p>
          -&nbsp;&nbsp; partecipare alle Assemblee (se in regola con il
          pagamento della Quota Associativa annuale) e di votare direttamente o
          per delega se iscritti da almeno tre mesi nel Libro degli Associati;
        </p>
        <p>
          -&nbsp; esaminare i Libri Sociali secondo le regole stabilite dal
          successivo articolo dodicesimo,
        </p>
        <p>
          -&nbsp; informarsi e conoscere i programmi con i quali l’Associazione
          intende attuare gli scopi sociali,
        </p>
        <p>partecipare alle attività promosse dall’Associazione;</p>
        <p>
          -&nbsp;&nbsp; dare le dimissioni in qualsiasi momento, tramite
          comunicazione scritta, da rivolgere al Consiglio Direttivo.
        </p>
        <p>
          <strong>Gli Associati sono obbligati a:</strong>
        </p>
        <p>
          -&nbsp; osservare le norme del presente Statuto e le deliberazioni
          adottate dagli Organi Sociali,
        </p>
        <p>
          -&nbsp; versare la Quota Associativa annuale stabilita dall’Assemblea;
          contribuire al perseguimento degli&nbsp; scopi&nbsp;
          dell’Associazione,
        </p>
        <p>
          attraverso il proprio apporto finanziario o prestando la propria
          attività diretta, spontanea e gratuita;
        </p>
        <p>
          -&nbsp; mantenere un comportamento conforme alle finalità
          dell’AssociaZione. <strong>Articolo ottavo: </strong>la qualifica di
          Associato può venire meno per i seguenti motivi:
        </p>
        <p>a)&nbsp; per decesso:</p>
        <p>
          b)&nbsp; per dimissioni da comunicarsi per iscritto con lettera,
          inviata al Consiglio Direttivo dell’Associazione ed avranno decorrenza
          dalla data indicata nella stessa, in mancanza di indicazione precisa,
          avranno decorrenza due giorni dopo il ricevimento di detta
          comunicazione;
        </p>
        <p>
          c)&nbsp; per decadenza, nel caso in cui venga a mancare uno dei
          requisiti per cui l'Associato è stato ammesso;
        </p>
        <p>
          d)&nbsp;&nbsp; per mancato versamento della Quota Associativa annuale
          entro i termini previsti;
        </p>
        <p>
          e)&nbsp;&nbsp; per decisione di esclusione deliberata dal Consiglio
          Direttivo e dall’Assemblea degli Associati, per i seguenti motivi:
        </p>
        <p>
          1) sistematica assenza, senza giustificato motivo, dalle riunioni
          del1'Assemblea;&nbsp;
        </p>
        <p>
          2)per gravi e documentate violazioni dei doveri degli Associati o
          comportamento contrastante con gli Scopi Statutari.
        </p>
        <p>
          Esse non danno luogo a restituzione di Quote Sociali; inoltre la quota
          sociale è intrasmissibile, non rimborsabile e non rivalutabile.
        </p>
        <p>
          L’associato può ricorrere all’Autorità Giudiziaria entro tre mesi dal
          giorno di notifica della deliberazione.
        </p>
        <p>CONTRIBUTI</p>
        <p>
          Articolo nono: i contributi si distinguono in Ordinari e Straordinari.
          Sono Ordinari quelli fissati come contributo di iscrizione annuale
          d'esercizio (Quota Associativa Annuale), sono straordinari quelli
          fissati una tantum.
        </p>
        <p>
          I Contributi Ordinari sono fissati annualmente e sono dovuti,
          unitamente a quelli straordinari, dagli Associati Ordinari, da quelli
          Sostenitori a dagli Associati Junior.
        </p>
        <p>
          La Quota Associativa dovuta per ciascun Associato iscritto
          all'Associazione è annuale ed indivisibile.
        </p>
        <p>
          L'Associato che cessa, per qualsiasi causa, di far parte
          dell'Associazione ha l'obbligo di versare i contributi ordinari e
          straordinari stabiliti per tutta la durata dell'Esercizio Sociale nel
          corso del quale è avvenuta la cessazione della qualità di Associato.
        </p>
        <p>ASSEMBLEA</p>
        <p>
          Articolo decimo: l’Assemblea è composta dagli Associati
          dell’Associazione, iscritti nel Libro degli Associati e in regola con
          il versamento della quota associativa; è l'organo sovrano. Essa si
          riunisce in seduta ordinaria almeno una volta all'anno, su
          convocazione del Presidente per deliberare il bilancio preventivo e
          quello consuntivo; inoltre è convocata per l'elezione degli Organi
          Sociali; è presieduta dal Presidente dell’Associazione o, in sua
          assenza, dal Vicepresidente. Ciascun Associato ha diritto ad un voto e
          puÒ farsi rappresentare da altro Associato, conferendo delega scritta,
          anche in calce all’avviso di convocazione. Ciascun Associato può
          rappresentare al massimo un altro Associato.
        </p>
        <p>
          Le riunioni dell’Assemblea si possono svolgere anche per
          audioconferenza o videoconferenza alle seguenti condizioni di cui si
          darà atto nei relativi verbali:
        </p>
        <p>
          a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; che siano presenti nello stesso luogo
          il Presidente ed il Segretario, se nominato, che provvederanno alla
          formazione e sottoscrizione del Verbale, dovendosi ritenere svolta la
          riunione in detto luogo;
        </p>
        <p>
          b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; che sia consentito al Presidente di
          accertare l'identità degli intervenuti ed il regolare svolgimento
          della riunione e constatare e proclamare i risultati della votazione;
        </p>
        <p>
          c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; che&nbsp; sia&nbsp; consentito&nbsp;
          al soggetto&nbsp; verbalizzante&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; di
          percepire
        </p>
        <p>
          adeguatamente gli eventi della riunione oggetto di verbalizzazione;
        </p>
        <p>
          d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; che sia
          consentito&nbsp;&nbsp;&nbsp;&nbsp; agli&nbsp; intervenuti&nbsp;
          di&nbsp; partecipare&nbsp; alla discussione ed alla votazione
          simultanea sugli argomenti all'ordine del giorno, nonché di visionare,
          ricevere o trasmettere documenti. Delle riunioni dell’Assemblea è
          redatto il Verbale, sottoscritto dal Presidente e dal verbalizzante e
          trascritto nel Libro verbali delle Assemblee.
        </p>
        <p>
          L’Assemblea può essere ordinaria o straordinaria. È straordinaria
          quella convocata per la modifica dello Statuto, per lo scioglimento,
          la trasformazione, la fusione o la scissione dell’Associazione. È
          ordinaria in tutti gli altri casi. L'Assemblea Straordinaria è
          convocata su iniziativa del Presidente o su richiesta di almeno la
          metà degli Associati,&nbsp;&nbsp; regolarmente iscritti al Libro Soci
          da oltre sei mesi.
        </p>
        <p>
          L'avviso di convocazione conterrà l'ora ed il giorno indicati per la
          stessa, oltre che l'elenco degli argomenti all'ordine del giorno e le
          modalità di partecipazione.
        </p>
        <p>
          L'Assemblea è valida, in prima convocazione, con la presenza della
          maggioranza degli Associati; in seconda, con qualunque numero di
          presenti.
        </p>
        <p>
          Le delibere effettuate dall'Assemblea potranno essere fatte anche per
          alzata di mano. La comunicazione di convocazione di Assemblea deve
          avvenire almeno tre giorni prima della data stabilita per lettera
          semplice, con comunicazione telefonica, via e-mail e/o con affissione
          di convocazione presso la sede de1l’Associazione.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>INCOMPATIBILITA’ DELLE CARICHE</strong>
        </p>
        <p>
          <strong>Articolo undicesimo: </strong>è fatto divieto agli
          amministratori delle Associazioni e Società Sportive Dilettantistiche
          di ricoprire qualsiasi carica in altre Società o Associazioni Sportive
          Dilettantistiche nell'ambito della medesima Federazione Sportiva
        </p>
        <p>
          Nazionale, disciplina sportiva associata o Ente di Promozione Sportiva
          riconosciuti dal CONI e, ove paralimpici, riconosciuti dal CIP.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>ORGANI DELL'ASSOCIAZIONE</p>
        <p>Articolo dodicesimo: sono organi dell’Associazione:</p>
        <p>
          a)&nbsp;&nbsp; L'Assemblea degli Associati e l’Assemblea Straordinaria
          degli Associati,
        </p>
        <p>b)&nbsp; Il Consiglio Direttivo;</p>
        <p>c)&nbsp; Il Presidente;</p>
        <p>d)&nbsp; Il Vicepresidente;</p>
        <p>e)&nbsp; Il Segretario,</p>
        <p>f)&nbsp; L’Organo di Controllo.</p>
        <p>
          Le cariche sociali sono gratuite, fatto salvo il diritto al rimborso
          delle spese, effettivamente sostenute e anticipate, nell’interesse
          dell’Associazione; per quanto riguarda tutti gli altri Associati,
          l’Associazione potrà avvalersi di Volontari Sportivi o di Lavoratori
          Sportivi, da inquadrarsi con appositi contratti secondo le Normative
          Vigenti disposte dalla Riforma del Terzo Settore.
        </p>
        <p>a) L'Assemblea Ordinaria degli Associati:</p>
        <p>1)&nbsp; definisce i programmi di massima dell'attività sportiva;</p>
        <p>2)&nbsp; approva il rendiconto economico dell’anno trascorso;</p>
        <p>
          3)&nbsp;&nbsp; eleggere il Presidente e il Consiglio Direttivo, dopo
          la prima scadenza,
        </p>
        <p>
          4)&nbsp;&nbsp;&nbsp; eleggere&nbsp; i&nbsp; sostituti&nbsp; dei&nbsp;
          membri&nbsp; del&nbsp; Consiglio&nbsp; Direttivo eventualmente
          dimissionari o dopo la scadenza del mandato:
        </p>
        <p>5)&nbsp; delibera sull'esclusione degli Associati;</p>
        <p>
          6)&nbsp;&nbsp; deliberare su ogni altro argomento di carattere
          ordinario e di interesse generale posto all’ordine del giorno.
        </p>
        <p>L'Assemblea Straordinaria degli Associati:</p>
        <p>
          1)&nbsp; delibera sulle proposte di modifica dell'Atto Costitutivo o
          dello Statuto;
        </p>
        <p>2)&nbsp; delibera lo scioglimento dell'Associazione.</p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il Consiglio Direttivo:</p>
        <p>
          1)&nbsp; governa l’Associazione ed opera in attuazione delle volontà e
          degli indirizzi generali del1’Assemblea alla quale risponde
          direttamente e dalla quale può essere revocato.
        </p>
        <p>
          2)&nbsp; è composto da numero minimo di tre fino ad un massimo di
          cinque membri eletti dall’Assemblea tra le persone fisiche associate.
          Il primo Consiglio Direttivo è formato dagli Associati Fondatori
          sottoscrittori del presente Atto e rimarrà in carica per un
          quinquennio, salvo recesso dei singoli.
        </p>
        <p>
          3)&nbsp; si riunisce almeno una volta ogni anno e quando se ne ravvisi
          la necessità, con convocazioni fissate dal Consiglio stesso.
        </p>
        <p>
          4)&nbsp; è convocato ogni qual volta il Presidente lo ritenga
          opportuno e quando ne faccia richiesta la maggioranza dei componenti
          dell’Organo di Amministrazione.
        </p>
        <p>
          5)&nbsp;&nbsp; è validamente costituito quando è presente la
          maggioranza dei componenti. Nel caso in cui sia composto da soli tre
          membri, è validamente costituito quando sono presenti tutti. Le
          deliberazioni sono assunte a maggioranza dei presenti.
        </p>
        <p>
          6)&nbsp; la convocazione delle riunioni del Consiglio Direttivo segue
          le stesse regole di quella dell’Assemblea, le stesse riunioni possono
          svolgersi anche per audioconferenza o videoconferenza.
        </p>
        <p>Ha il compito di:</p>
        <p>
          1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          sviluppare&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; le
          indicazioni&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          operative&nbsp;&nbsp; nei programmi&nbsp; stabiliti dall'Assemb1ea e
          curarne l'attuazione;
        </p>
        <p>
          2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; provvedere all'attività'
          ordinaria dell'Associazione; 3)deliberare l'ammontare delle quote
          associative: 4)deliberare sulla perdita delle qualità di Associato,
          5)approvare le richieste di iscrizione dei nuovi Associati;
        </p>
        <p>6) curare la tenuta dei Libri Sociali di sua competenza;</p>
        <p>
          7) deliberare in ordine all’ammissione degli Associati, accogliere o
          rigettare le domande degli aspiranti Associati.
        </p>
        <p>
          Il Consiglio Direttivo potrà inoltre essere convocato ogni volta che
          ne venga fatta richiesta dalla maggioranza dei suoi componenti.
          Convocazione e ordine del giorno saranno comunicati a mezzo e-mail o
          telefonicamente, con preavviso di almeno quarantotto ore.
        </p>
        <p>
          Il Consiglio Direttivo delibera con il voto favorevole della
          maggioranza dei partecipanti, in caso di parità nella votazione, il
          voto del Presidente varrà doppio.
        </p>
        <p>
          Il Consiglio Direttivo rimane in carica cinque anni ed al termine
          potrà essere rieletto per un uguale periodo di tempo.
        </p>
        <p>
          Le sedute e le deliberazioni del Consiglio sono fatte constare da
          processo verbale sottoscritto dal Presidente e dal Segretario.
        </p>
        <p>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il Presidente:</p>
        <p>
          1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; convoca e presiede le
          riunioni dell'Assemblea, 2)sovrintende le riunioni del Consiglio
          Direttivo; 3)cura l’attuazione dei programmi dell’Associazione;
        </p>
        <p>
          4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; dura in carica quanto
          l'organo di Amministrazione e cessa per scadenza del mandato, per
          dimissioni volontarie o per eventuale revoca decisa dall’assemblea;
          può essere nuovamente eletto per più mandati, anche consecutivi,
        </p>
        <p>
          5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ha piena facoltà di
          incassare somme per qualsiasi importo e rilasciare quietanza
          liberatoria, aprire conti correnti bancari o postali, richiedere fidi,
          operare su di essi, acquistare e vendere beni e/o autoveicoli. Egli
          può delegare il potere di firma a terze persone nell’interesse
          dell’Associazione, al Segretario e al Vicepresidente per singoli atti
          o per categorie di atti.
        </p>
        <p>
          Il Presidente vigila sull’esecuzione delle delibere dell’Assemblea e
          del Consiglio Direttivo, nei casi di urgenza può esercitare i poteri
          del Consiglio Direttivo.
        </p>
        <p>
          d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il Vicepresidente:
          coadiuva e sostituisce il Presidente in ogni sua attribuzione, in caso
          di sua assenza o impedimento, assumendo i poteri.
        </p>
        <p>
          e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il Segretario: è nominato
          dal Presidente, dura in carica cinque anni e ha la possibilità di
          essere riconfermato.<strong>&nbsp;</strong>
        </p>
        <p>
          Svolge la mansione di promuovere e curare le relazioni pubbliche per
          il buon funzionamento dell’Associazione, sovrintende l’organizzazione
          burocratica dell’Associazione, provvede a redigere i verbali durante
          le Assemblee e le riunioni del Consiglio Direttivo, ed alla loro
          conservazione.
        </p>
        <p>f) L’Organo di Controllo:</p>
        <p>
          1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; È facoltà de1l’Assemblea nominare un
          Organo di Controllo, anche monocratico. L’Organo di Controllo, al
          quale si applica l’art. 2399 del Codice Civile, deve essere scelto tra
          le categorie di soggetti di cui all’art. 2397, comma 2 del Codice
          Civile.
        </p>
        <p>
          2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spetta all’Organo di Controllo il
          controllo della contabilità e della gestione amministrativa, in
          particolare è compito dell’organo medesimo:
        </p>
        <p>
          •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; vigilare sull'osservanza della
          Legge, dello Statuto e sul rispetto dei principi di corretta
          amministrazione,
        </p>
        <p>
          •&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; vigilare sull'adeguatezza
          dell'assetto organizzativo, amministrativo e contabile e sul suo
          concreto funzionamento;
        </p>
        <p>
          3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; esso può esercitare, al ricorrere
          delle previsioni di Legge e previa delibera dell’Assemblea, la
          revisione dei conti.
        </p>
        <p>
          4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; il componente dell’Organo di
          Controllo può in qualsiasi momento procedere ad atti di ispezione e di
          controllo e, a tal fine, può chiedere agli amministratori notizie
          sull'andamento delle operazioni sociali o su determinati affari.
        </p>
        <p>
          Articolo tredicesimo (Libri Sociali): L’Associazione ha l’obbligo di
          tenere i seguenti Libri Sociali:
        </p>
        <p>
          al il Libro degli Associati tenuto a cura del Consiglio Direttivo
          (Organo di Amministrazione);
        </p>
        <p>
          b)&nbsp; il libro delle adunanze e delle deliberazioni delle
          Assemblee, in cui devono essere trascritti anche i Verbali redatti per
          atto pubblico, tenuto a cura del consiglio;
        </p>
        <p>
          c)&nbsp;&nbsp; il libro delle adunanze e delle deliberazioni
          dell’Organo di Amministrazione, dell’Organo di Controllo, e degli
          altri organi sociali, tenuti a cura dell’organo a cui si riferiscono,
        </p>
        <p>
          d)&nbsp;&nbsp;&nbsp; il Registro dei Volontari, tenuto a cura
          dell’Organo di Amministrazione.
        </p>
        <p>
          Tutti gli Associati, in regola con il versamento della quota
          associativa, hanno il diritto di esaminare i Libri Sociali tenuti
          presso la Sede Legale dell’Ente, entro 15 giorni dalla data della
          richiesta formulata all’organo competente.
        </p>
        <p>
          Articolo quattordicesimo: l'esercizio finanziario si chiude al 31
          dicembre di ogni anno.
        </p>
        <p>
          Entro il termine massimo del 30 aprile dell'anno successivo, sarà
          presentato all'approvazione dell'Assemblea:
        </p>
        <p>il Bilancio Consuntivo dell'anno precedente,</p>
        <p>
          b) la relazione del Consiglio Direttivo sull'andamento
          dell'Associazione, con particolare riferimento agli obiettivi
          raggiunti, allo stato attuale dell'Associazione ed ai programmi
          sull’anno in corso.
        </p>
        <p>
          c) l’Organo di Amministrazione documenta il carattere secondario e
          strumentale delle attività diverse di cui all'art. 9 del D.lgs. n.
          36/2021, nella relazione di missione o, nell’ipotesi in cui il
          rendiconto sia redatto nella forma del rendiconto per cassa, in una
          annotazione in calce al rendiconto medesimo.
        </p>
        <p>
          I membri degli Organi Sociali non percepiscono alcun compenso ed hanno
          diritto al solo rimborso delle spese effettuate secondo le normative
          vigenti riservate alle Associazioni Sportive Dilettantistiche.
        </p>
        <p>
          Vige il divieto di distribuire agli Associati: utili, avanzi di
          gestione e fondi.
        </p>
        <p>
          Articolo quattordicesimo: per le obbligazioni assunte dalle persone
          che rappresentano l’Associazione, i terzi possono far valere i loro
          diritti sul fondo comune. Delle obbligazioni assunte rispondono,
          personalmente e solidalmente le persone che hanno agito in nome e per
          conto dell’Associazione.
        </p>
        <p>
          Articolo quindicesimo: per quanto non è previsto dal presente Statuto,
          si fa riferimento alle normative vigenti in materia ed ai principi
          generali dell’ordinamento giuridico in materia di Associazioni
          Sportive Dilettantistiche, di cui al Decreto Legislativo 28 febbraio
          2021, n. 36 e ss.mm.ii. e, in quanto compatibili, alle disposizioni
          del Codice Civile.
        </p>
        <p>Arese (Mi), li 02/10/2023.</p>
        <p>&nbsp;</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&nbsp;&nbsp;</strong>
          <strong>
            CONSENSO AL TRATTAMENTO DEI DATI PERSONALI RIGUARDANTI
            L’ASSOCIAZIONE SPORTIVA/SOCIO{" "}
          </strong>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Con la presa visione del presente documento dichiaro di aver preso
          visione e di
        </p>
        <p>
          accettare, ai sensi e per gli effetti degli art.13 e 23 del D. L.gs.
          n. 196/2003 il
        </p>
        <p>
          trattamento dei dati personali secondo le modalità e nei limiti di cui
        </p>
        <p>all’informativa allegata.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>INFORMATIVA EX ART.13 DEL D.LGS. N. 196/2003</strong>
        </p>
        <p>Gentile Signore/a</p>
        <p>
          Desideriamo informarLa che il D. Lgs. N 196 del 30 giugno 2003 (Codice
          in materia di protezione dei dati personali) prevede la tutela delle
          persone e altri soggetti rispetto al trattamento dei dati personali.
        </p>
        <p>
          Secondo la normativa indicata, tale trattamento sarà improntato ai
          principi di correttezza, liceità e trasparenza e di tutela della Sua
          riservatezza e dei Suoi diritti.
        </p>
        <p>
          Ai sensi dell’articolo 13 del D. Lgs. N. 196/2003, pertanto. Le
          forniamo le seguenti informazioni:
        </p>
        <p>
          1. I dati da Lei forniti verranno trattati per le seguenti finalità:
          adesione all’associazione sportiva, e partecipazione a
          eventi/manifestazioni sportive presso l’associazione sportiva
          dilettantistica denominata ‘Racelab ASD’ sita in Via Juan Manuel
          Fangio snc. Lainate (MI) 20045
        </p>
        <p>2. Il trattamento sarà effettuato in modalità informatizzata</p>
        <p>
          3. Il conferimento dei dati è obbligatorio per poter associare
          all’associazione sportiva dilettantistica, altrimenti non potrà
          prendere parte agli eventi, manifestazioni dalla suddetta Racelab ASD.
        </p>
        <p>
          4.&nbsp; I dati non saranno comunicati ad altri soggetti, ne saranno
          oggetto di diffusione.
        </p>
        <p>
          5. Il responsabile del trattamento è ‘Racelab ASD’ sito in Via Juan
          Manuel Fangio snc. Lainate (MI) 20045
        </p>
        <p>
          6. In ogni momento potrà esercitare i Suoi diritti nei confronti del
          titolare del trattamento, ai sensi dell’art. 7 del D. Lgs. n. 196/2003
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
      </>
    </div>
  );
}
