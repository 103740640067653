import { keyframes } from "styled-components";

const slideLeft = keyframes`
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0;
  }
`;
const slideTop = keyframes`
  from {
    margin-top: 100%;
  }
  to {
    margin-top: 0px;
  }
`;
const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const logoAnimation = keyframes`

  0% {
    --gradientBlack:0% ;
  
    --gradientTransparent:0%;
    --bgLogoText: url("");

  }
  50% {
    --gradientBlack:100% 
    ;
  
  --gradientTransparent:0%
    ;
    --bgLogoText: url("");


    }
    51% {
    --gradientBlack:100% 
    ;
  
  --gradientTransparent:0%
    ;
    --bgLogoText: url("/drivingsucess.webp");



    }
    100%{
  
      --gradientBlack:100%;
      --gradientTransparent:100%;

      --bgLogoText: url("/drivingsucess.webp");

   
    }
`;
export { slideLeft, slideTop, opacity, logoAnimation };
