import styled from "styled-components";
import { RaceButton } from "../../components/button";
import {
  DinamicSession,
  SportCarSection,
  TestSession,
  SuperCarSection,
  RacingCarSection,
  VisitorSection,
} from "./Sessions";
import { useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

const Title = styled.div`
  font-family: "Handel Gothic D";
  font-weight: bold;
  font-style: normal;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 1.9em;

  text-align: center;
  color: rgb(129, 53, 138);
  font-family: "Handel Gothic D";
  font-weight: bold;
  font-style: normal;

  text-shadow: 3px 3px 5px #401345, -3px -3px 5px #401345;
`;

const Container = styled.div`
  margin-top: 20px;
  scroll-margin-top: var(--scroll-offset);

  padding: 50px;
  & p {
    font-size: 0.8em;
  }
`;
const FlexContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    height: 700px;
    margin-right: 50px;
  }
`;
const RightContainer = styled.div`
  @media (min-width: 800px) {
    flex: 1;
  }
`;

export function Shop() {
  const sections = {
    none: <></>,
    test: <TestSession />,
    dinamic: <DinamicSession />,
    sportCar: <SportCarSection />,
    superCar: <SuperCarSection />,
    racingCar: <RacingCarSection />,
    visitors: <VisitorSection />,
  };
  const [section, setSection] = useState<keyof typeof sections>("none");
  const url = new URL(window.location.href);
  useEffect(() => {
    if (url.hash === "#shopracing") {
      setSection("racingCar");
    }
  }, [url.hash]);

  const size = useWindowSize();

  var mobile = false;
  if (size.width < 800) {
    mobile = true;
  } else {
    mobile = false;
  }
  function ButtonSection(props: {
    name: string;
    section: keyof typeof sections;
  }) {
    return (
      <RaceButton
        onClick={() => setSection(props.section)}
        className={section === props.section ? "active" : ""}
      >
        {props.name}
      </RaceButton>
    );
  }
  return <></>;
  return (
    <Container id="shop">
      <Title>Evento di lancio</Title>
      <Title>16 & 17 MARZO</Title>
      <FlexContainer>
        {(section === "none" || !mobile) && (
          <LeftContainer>
            <ButtonSection name="VISITATORI" section="visitors" />
            <ButtonSection name="SESSIONI GRATUITE" section="test" />
            <ButtonSection name="30 MININUTI SIM DINAMICO" section="dinamic" />
            <ButtonSection name="SPORT CAR 3 GIRI" section="sportCar" />
            <ButtonSection name="SUPERCAR 3 GIRI" section="superCar" />
            <ButtonSection name="3 GIRI DA PASSEGGERO" section="racingCar" />
          </LeftContainer>
        )}
        <RightContainer>
          {sections[section]}
          <br></br>
          {section !== "none" && mobile && (
            <RaceButton onClick={() => setSection("none")}>Indietro</RaceButton>
          )}
        </RightContainer>
      </FlexContainer>
    </Container>
  );
}
