import styled from "styled-components";
import { DoubleRow } from "./components";
import { useCheckout } from "./useCheckout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;

  flex: 1;
  h2 {
    font-family: "Handel Gothic D";
  }
  height: 100%;
  p {
    height: 50px;
  }
`;
const ImgSim = styled.div`
  width: 100%;
  background-image: url("/our-services/simleft.webp");
  background-size: cover;
  background-position: center;
  aspect-ratio: 14/10;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SimLeft = styled(ImgSim)`
  background-image: url("/our-services/simleft.webp");
`;
const SimRight = styled(ImgSim)`
  background-image: url("/our-services/simright.webp");
`;
export function SimBox() {
  const GoCheckout = useCheckout();
  return (
    <DoubleRow>
      <Container>
        <h2>
          Simulatore dinamico <br />3 gradi di libertà
        </h2>
        <p>Simulatore con posizione di guida GT / RALLY / DRIFTING</p>
        <SimLeft />
        <button onClick={() => GoCheckout("49145170297143")}>15€</button>
      </Container>
      <Container>
        <h2>
          Simulatore dinamico <br />5 gradi di libertà
        </h2>
        <p>Simulatore con posizione di guida FORMULA / GT</p>
        <SimRight />
        <button onClick={() => GoCheckout("49145170329911")}>20€</button>
      </Container>
    </DoubleRow>
  );
}
