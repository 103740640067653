import styled from "styled-components";

export const DoubleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
  height: 100%;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
export const TopImg = styled.div<{
  $bgImage: string;
}>`
  width: 100%;

  background-image: ${(props) => `url(${props.$bgImage})`};
  background-size: cover;
  background-position: center;
  aspect-ratio: 3/1;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 100px;
  img {
    max-width: 200px;
  }

  h1 {
    color: var(--violet-color);
    font-size: 50px;
    font-weight: bold;
    font-family: "Handel Gothic D";
    margin-bottom: 0;
  }
  h2 {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 5px;
    font-family: "Handel Gothic D";
  }
  p {
    margin-top: 0;

    font-size: 15px;
  }
  button {
    font-family: "Handel Gothic D";
    width: 100%;
    background-color: transparent;
    color: white;
    padding: 15px;
    border: 2px solid var(--violet-color);
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      background-color: var(--violet-color);
    }
  }
`;
export const PdContainer = styled.div`
  padding: 20px;
`;
