import styled from "styled-components";
import { RaceButton } from "../components/button";
import { Footer } from "./footer";

const TopLogo = styled.div`
  width: 100vw;
  height: 200px;
  background-size: contain;
  background-image: url("timeAttackLogo.webp");
  background-repeat: no-repeat;
  background-position: center;
  margin: 30px 0px;
`;

const ImageBlock = styled.div`
  width: 100vw;
  height: auto;
  aspect-ratio: 16 / 9;
  background-size: contain;
  background-image: url("guida.webp");
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const TitleText = styled.div`
  max-width: 700px;
  text-transform: uppercase;
  font-family: Handel Gothic D;
  font-size: 3em;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 1100px) {
    font-size: 1.5em;
  }
`;

const Pink = styled.span`
  color: var(--violet-color);
`;
const CircledText = styled.div`
  border-radius: 20px;
  max-width: 500px;
  padding: 20px;
  border-color: var(--violet-color);
  border-width: 4px;
  border-style: solid;
  color: white;
  font-family: Handel Gothic D;

  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  text-align: center;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Handel Gothic D;

  text-align: center;
`;

const Text = styled.div`
  max-width: 500px;
  font-family: Handel Gothic D;
`;
const InfoButton = styled(RaceButton)`
  bottom: 0;

  margin: 25px 50px;
  z-index: 4;
  background-color: #45a11a;
  margin-bottom: 100px;
`;

const InstrContainer = styled.ol`
  background-image: url("kartbanner.webp");
  background-repeat: no-repeat;
  background-position: top;

  font-family: Handel Gothic D;

  display: flex;
  width: 100%;
  padding: 0px;
  min-height: 500px;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding-top: 20px;
  }
`;
const InstrBlock = styled.li`
  max-width: 300px;
  height: 200px;
  @media screen and (max-width: 1100px) {
    height: auto;
  }
`;
const PinkLlink = styled.a`
  color: var(--violet-color);
  text-decoration: underline var(--violet-color);
`;

export function TimeAttack() {
  return (
    <>
      <TopLogo></TopLogo>
      <ImageBlock>
        <TitleText>
          Lasciati guidare dal <Pink>team raceX</Pink> verso l’
          <Pink>esperienza della pista</Pink>
        </TitleText>
      </ImageBlock>
      <CenteredContainer>
        <TitleText>
          <Pink>Come funziona</Pink>
        </TitleText>
        <CircledText>
          <Pink>Se è la tua prima esperienza</Pink> su un SIM o su un Kart
          contattaci, ti sapremo dare tutte le indicazioni necessarie per poter
          partecipare al CONTEST.
        </CircledText>
        <InfoButton>
          <a href="https://api.whatsapp.com/send?phone=393298864486">
            Richiedi più informazioni
          </a>
        </InfoButton>
        <TitleText>
          <Pink>HAI GIA’ ESPERIENZA?</Pink>
        </TitleText>
        <Text>
          Sei mai stato su un simulatore di guida professionale o a bordo di un
          kart? Se la risposta è <Pink>SI</Pink>, queste sono le istruzioni da
          seguire:
        </Text>
      </CenteredContainer>
      <InstrContainer>
        <InstrBlock>
          Compila il
          <PinkLlink href="https://irkpromotion.com/time-attack-sim-racing/#modulo">
            modulo di registrazione
          </PinkLlink>
          qui riportato. Una volta effettuato il pagamento della quota di 20,00
          € riceverai il tuo coupon per partecipare.
        </InstrBlock>
        <InstrBlock>
          Vieni al <Pink>raceLAB</Pink>, sali a bordo di un simulatore e ottieni
          il miglior tempo.
        </InstrBlock>
        <InstrBlock>
          Il miglior pilota di ogni round vincerà una giornata di preparazione
          presso il <PinkLlink href="/">centro Race X</PinkLlink> e la
          partecipazione ad un evento <Pink>R-Max Championship.</Pink>
        </InstrBlock>
      </InstrContainer>
    </>
  );
}
