import { animated, useInView } from "@react-spring/web";
import styled from "styled-components";

const ChiSiamoContainer = styled.div`
  display: flex;

  scroll-margin-top: var(--scroll-offset);

  color: white;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;

  & > div:nth-child(1) {
    font-family: "Handel Gothic D";
    font-weight: bold;
    position: relative;
    font-style: normal;
    font-size: 40px;
    color: var(--violet-color);
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 20px;

    &::after {
      content: "";

      position: absolute;
      inset: 0;
      width: 100%;
      border-bottom: 4px solid rgb(83, 137, 132);
    }
  }
  & > div:nth-child(2) {
    max-width: 800px;
    font-size: 20px;
  }
`;

export function ChiSiamo() {
  const [ref, springs] = useInView(() => ({
    from: {
      x: -100,
    },
    to: {
      x: 0,
    },
  }));
  return (
    <ChiSiamoContainer id="chi_siamo">
      <animated.div ref={ref} style={springs}>
        Chi Siamo
      </animated.div>
      <animated.div ref={ref} style={springs}>
        l team di raceX include piloti professionisti con esperienza
        internazionale, istruttori di guida sportiva con certificazione federale
        ACI, ingegneri di pista attivi nelle principali serie turismo e formula,
        preparatori atletici che seguono da anni famosi piloti professionisti,
        psicologi dello sport inventori del metodo “Psicologia del
        Potenziamento®️” , esperti di comunicazione specializzati nel
        massimizzare la visibilità degli atleti, dei partners e delle aziende
        sponsor.
      </animated.div>
    </ChiSiamoContainer>
  );
}
