import styled from "styled-components";
import { animated, useInView } from "@react-spring/web";
import React from "react";

const TextSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px;

  color: white;

  & > div {
    max-width: 1200px;
  }

  &:nth-child(even) {
    align-items: flex-end;
    text-align: right;
  }

  &:nth-child(even) > div:nth-child(odd) > div:nth-child(1)::after {
    left: auto;
  }

  & > div:nth-child(1) {
    font-size: 40px;
    color: var(--violet-color);
    font-family: "Handel Gothic D";
    font-weight: bold;
    font-style: normal;
  }
  & > div:nth-child(2) {
    font-size: 45px;
    font-family: "Handel Gothic D";
    font-weight: bold;
    font-style: normal;

    text-transform: uppercase;
  }
  & > div:nth-child(3) {
    margin-top: 10px;
  }
  @media (max-width: 800px) {
    padding: 20px;
    & > div:nth-child(1) {
      font-size: 30px !important;
    }
    & > div:nth-child(2) {
      font-size: 30px !important;
    }
  }
`;
export function TextSection(props: {
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  text: string | React.ReactElement;
  invertAnimation?: boolean;
}) {
  const [ref, springs] = useInView(() => ({
    from: {
      x: props.invertAnimation ? 100 : -100,
    },
    to: {
      x: 0,
    },
  }));
  return (
    <TextSectionContainer>
      <animated.div ref={ref} style={springs}>
        {props.title}
      </animated.div>
      <animated.div ref={ref} style={springs}>
        {props.subtitle}
      </animated.div>
      <animated.div ref={ref} style={springs}>
        {props.text}
      </animated.div>
    </TextSectionContainer>
  );
}
